import Customer_navbar from '@/components/Customer/CustomerNavView.vue'
 import jQuery from 'jquery';

export default {
  components: {
    Customer_navbar
  },
  mounted() {
    const $search_icon = jQuery('#search_icon');
    const $search_input_box = jQuery('#search_input');

    // Toggle the search input and icon on click
    $search_icon.on('click', function(event) {
      event.stopPropagation();
      $search_input_box.toggleClass('d-none'); // Show/hide the search input
      $search_icon.toggleClass('bx-search bx-x'); // Toggle between search and close icon
    });
  },
}
