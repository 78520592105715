import Navbar from '@/components/Brand/Navbar/NavbarView.vue'
 import jQuery from 'jquery';
 import $ from 'jquery';

export default { 
  components: {
    Navbar
  },
    mounted() {
      const $search_icon = jQuery('#search_icon');
      const $search_input_box = jQuery('#search_input');

      // Toggle the search input and icon on click
      $search_icon.on('click', function(event) {
        event.stopPropagation();
        $search_input_box.toggleClass('d-none'); // Show/hide the search input
        $search_icon.toggleClass('bx-search bx-x'); // Toggle between search and close icon
      });
    },
    data() {
      return { username: '', logoUrl: require('@/assets/images/icon.jpg') };
    },
    created() {
      this.fetchUserName();
    },
    methods: {
      goToUpdateBrand() {
        this.$router.push( '/update_user');
        
      },

      async fetchUserName() {
        const token = localStorage.getItem('token');
        try {
          const FETCH_USER_NAME_URL = `${process.env.VUE_APP_API_BASE_URL}/brand_management/get_brand_info/`;

          const response = await $.ajax({
            url: FETCH_USER_NAME_URL,
            method: 'GET',
            headers: {
            'Authorization': `Token ${token}`,
            'Content-Type': 'application/json',
            }
          });
  
          if (!response.ok) {
            throw new Error('Failed to fetch user data');
          }
  
          const data = await response.json();
  
          if (data.status === "success") {
            this.username = `${data.first_name} ${data.last_name}`; 
          } else {
            console.error('Error fetching user data:', data.error);
          }
        } catch (error) {
          console.error('Fetch Error:', error);
        }
      },
    }
  };
  