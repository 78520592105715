import $ from 'jquery'; 

export default {

  data() {
        return { logoUrl: require('@/assets/images/icon.jpg') };
      },

    methods: {
      goToUpdateBrand() {
        this.$router.push( '/update_brand');
      },

      async logout() {
        try {
          // Call the Django logout API
          const LOGOUT_URL = `${process.env.VUE_APP_API_BASE_URL}/system_management/logout/`;

          const response = await $.ajax({
            url: LOGOUT_URL,
            method: 'POST',
            headers: {
              'Authorization': `Token ${localStorage.getItem('token')}`,
              'Content-Type': 'application/json',
            },
          });
  
          if (!response.ok) {
            throw new Error('Logout failed');
          }
  
          // Remove the token from local storage
          localStorage.removeItem('token');
  
          // Redirect to login page
          this.$router.push('/login');
        } catch (error) {
          console.error('Logout Error:', error);
          // Optionally, handle error (e.g., show an error message)
          // e.g., Swal.fire({ icon: 'error', title: 'Logout Failed', text: 'Failed to log out. Please try again.' });
        }
      },
    },
  };
   


const navItems = document.querySelectorAll('.nav-item');

// Add click event listener to each nav-item
navItems.forEach(item => {
  item.addEventListener('click', function() {
    // Remove 'active' class from all nav-items
    navItems.forEach(nav => nav.classList.remove('active'));
    
    // Add 'active' class to the clicked nav-item
    this.classList.add('active');
  });
});

