<Home_navbar />


<div v-if="videos.length === 0" class="text-center    ">Loading videos...</div>
  <div v-else>
          <!-- First video full screen -->
    <div v-if="videos.length > 0">

        <div class="hero">
            <div class="video-container  border-0">
              <div class="fade-overlay"></div>
    
              <video :src="brand_videos[0].video_url" autoplay muted loop   class=" ">
                Your browser does not support the video tag.
            </video>
            </div>
            <div class="hero-content text-start text-light">
              <div class="d-flex align-content-center">
                <img :src="require('@/assets/images/orange-icon-logo.png')" alt="Kyosk Logo" class="hero-logo">
                <h3 class="ms-2"> </h3>
              </div> 
              <br>
                <p>2024 | COLLECTION:</p>
                <button class="btn btn-light text-dark" data-toggle="modal" data-target="#exampleModal"> <i class='bx bx-play-circle me-1'></i>Play</button>
                <button class="btn btn-outline-warning ms-3"> <i class='bx bxs-credit-card me-1'></i>More Brands</button>
            </div>
            <div class="hero-cards   ">
                <div class="container">
                    <div class="sub-scroller">
                        <input type="hidden" ref="video_id" :value="brand_videos[0]?.id">
                        <div class="logo-description-container col-md-7 ms-5">
                            <div id="main-header-indicators" class="carousel slide" data-ride="carousel">
                                
                                <br>
                                <div class="bg-grey px-5 d-flow-root subvideo-content mb-5 ">
                                <div class="d-flex justify-content-center d-none">
                                    <hr class="w-25 mx-auto text-center">
                                </div>
                                <div class="scroller-container">
                                    <div class="scroller">
                                        <div class="card-container justify-content-end " :style="{ transform: `translateX(-${currentPosition * (100 / visibleCards)}%)` }">
                                            <div v-for="image in image_files" :key="index" class="card">
                                            <!-- Your video content here -->
                                            <img :src="image.product.image_url" class="rounded-12" alt="Image description">
                                            <div class="card-body text-center">
                                                <h5 class="card-title text-warning">R{{ image.price }}</h5>
                                                <p class="card-text description text-white">{{ image.product.product_name }}</p>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                  </div> 
                                </div>
                            </div>
                        </div>
                    </div> 
                </div> 
            </div>
        </div>

              
      <!-- Full-screen Video element -->
        <div class="video-container-content d-none">
            
            <video :src="brand_videos[0].video_url" autoplay muted loop   class=" ">
                Your browser does not support the video tag.
            </video>
            <input type="hidden" ref="video_id" :value="brand_videos[0]?.id">
            <div class="logo-description-container col-md-7 ms-5">
                <div id="main-header-indicators" class="carousel slide" data-ride="carousel">
                    <ol class="carousel-indicators">
                        <li data-target="#main-header-indicators" data-slide-to="0" class="active"></li>
                     </ol>
                     <br>
                     <div class="bg-grey px-5 d-flow-root subvideo-content mb-5 ">
                        <div class="d-flex justify-content-center d-none">
                          <hr class="w-25 mx-auto text-center">
                        </div>
                        <div class="scroller-container">
                          <div class="scroller">
                            <div class="card-container justify-content-end " :style="{ transform: `translateX(-${currentPosition * (100 / visibleCards)}%)` }">
                                <div v-for="image in image_files" :key="index" class="card">
                                    <!-- Your video content here -->
                                    <img :src="image.product.image_url" class="rounded-12" alt="Image description">
                                    <div class="card-body text-start" >
                                        <p class="card-text description text-white">{{ image.product.product_name }}</p>
                                        <div class="d-flex align-items-start flex-column bd-highlight mb-3">
                                            <p class="card-text description text-white font-italic">By {{ image.product.brand__brand_name }}</p>
                                            <p class="card-text text-warning text-xxs">R{{ image.price }}</p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                          </div>
                          <button class="scroll-btn left" @click="scrollCards('left')"><i class='bx bxs-chevron-left'></i></button>
                          <button class="scroll-btn right" @click="scrollCards('right')"><i class='bx bxs-chevron-right'></i></button>
                        </div> 
                      </div>
                </div>
            </div>
            
            <div class="text-container">
                <div class="row">
                    <div class="bottom-left-text col-auto" data-toggle="modal" data-target="#exampleModal"> 
                        <i class='bx bx-play-circle fs-2'></i> Play
                    </div>
                    <div class="bottom-left-text col-auto bg-transparent view-products text-white">
                        <i class='bx bx-error-circle fs-2'></i>  
                        <span class="text-white ">Shop Now</span>
                    </div>
                </div>
            </div>
        </div>
 

      <!-- Modal -->
      <div class="modal fade" id="exampleModal" tabindex="-1" role="dialog" aria-labelledby="exampleModalLabel" aria-hidden="true">
        <div class="modal-dialog modal-xl" role="document">
          <div class="modal-content">
            <div class="modal-header">
              <h5 class="modal-title" id="exampleModalLabel">{{videos[0].caption}}</h5>
            </div>
            <div class="modal-body card border-0"> 
              <video :src="videos[0].video_url" autoplay muted loop   class="video">
                Your browser does not support the video tag.
                </video>
            </div> 
          </div>
        </div>
      </div>
      

      <div class="bg-grey px-5 d-flow-root subvideo-content mb-5">
        <h1 class="text-center my-4 ms-4 text-grey fw-bold">  
          <span class="text-xs fw-normal text-dark">Khiosk</span> 
          <br>New Collection 
        </h1>
        <div class="d-flex justify-content-center d-none">
          <hr class="w-25 text-center">
        </div>
        <div class="scroller-container">
          <div class="scroller">
            <div class="card-container" :style="{ transform: `translateX(-${currentPosition * (100 / visibleCards)}%)` }">
              <div v-if="brand_videos.length > 0" v-for="(video, index) in brand_videos" :key="index" class="card" @mouseover="hoveredVideo = index" @mouseleave="hoveredVideo = null" >
                <img v-if="hoveredVideo !== index" :src="video.cover_image" alt="Video cover image" class="video-cover rounded-12 img-fluid h-100 object-fit-cover"/>

                <div v-if="hoveredVideo === index" class="hover-details">
                    <router-link :to="{ name: 'manage_content', params: { video_id: video.id } }">
                     
                    <video :src="video.video_url" ref="videoElement" autoplay muted loop class="rounded-12 video-preview" @mouseenter="showPopUp(index)" @mouseleave="hidePopUp(index)"></video>
                </router-link>
                  <div class="video-details text-center mt-2">
                    <h4>{{ video.title }}</h4>
                    <p>{{ video.description }}</p>
                    <p>{{ video.sub_description }}</p>
                  </div>
                </div>
              </div>
            </div>
          </div>
      
          <!-- Scroll buttons -->
          <button class="scroll-btn left" @click="scrollCards('left')">
            <i class="bx bxs-chevron-left"></i>
          </button>
          <button class="scroll-btn right" @click="scrollCards('right')">
            <i class="bx bxs-chevron-right"></i>
          </button>
        </div>
      </div>
       
    </div>

  
         

    <div class="container home-product">
      <h1 class="text-center my-4 ms-4 text-dark fw-bold">Latest Offers This Month</h1>
       
      <div class="row">
          <div class="card card-large">
              <span class="card-text text-dark">New Collection</span>
          </div>
          <div class="card card-small">
              <span class="card-text">Trends</span>
          </div>
      </div>
 
      <div class="row">
          <div class="card card-image home-band">
               <span class="card-text text-white">Brands  </span>
          </div>
          <div class="card card-image home-influencer">
              
              <span class="card-text text-white">Infuencer  </span>
          </div>
          <div class="card card-image home-shop"> 
              <img :src="require('@/assets/images/logo-icon-black.png')" alt="Kyosk Logo" class="w-25 h-25 rounded-12 d-none">

              <span class="card-text text-white">Shop Now </span>
          </div>
      </div>

    </div>
 

    <footer class="footer">
      <div class="footer-content">
          <div class="footer-links">
              <div class="footer-column">
                  <h3>Shop Now</h3>
                  <ul>
                      <li><a href="#">Rich Mnisi</a></li> 
                  </ul>
              </div>
              <div class="footer-column">
                  <h3>Trends</h3>
                  <ul>
                      <li><a href="#">South Africa</a></li> 
                  </ul>
              </div>
              <div class="footer-column">
                  <h3>Infuencer</h3>
                  <ul>
                      <li><a href="#">X</a></li>
                      <li><a href="#">Instagram</a></li> 
                  </ul>
              </div>
              <div class="footer-column">
                  <h3>Custom Care</h3>
                  <ul>
                      <li><a href="#">Contact Us</a></li>
                      <li><a href="#">Payment Method</a></li> 
                  </ul>
              </div>
          </div>
          <div class="footer-bottom">
              <div class="footer-legal">
                  <p>&copy; 2024 Kyosk. All rights reserved.</p>
                  <div class="footer-legal-links">
                      <a href="#">Privacy Policy</a>
                      <a href="#">Terms of Use</a> 
                  </div>
              </div>
          </div>
      </div>
  </footer>
</div> 


 
 
