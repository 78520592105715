 
<nav id="sidebar" class="sidebar">
  <div class=" d-flex align-items-start text-start">
      <div class="text-light">
        <strong>Influencer</strong> 
    </div>
  </div>
  
  <ul class="nav flex-column">
      <li class="nav-item"><a class="nav-link" href="#"><i class='bx bxs-dashboard'></i> Profile</a></li>
      <li class="nav-item"><a class="nav-link" href="#"><i class='bx bx-money'></i> Orders</a></li>
      <li class="nav-item"><a class="nav-link" href="#"><i class='bx bx-line-chart'></i> Payment</a></li> 
      <li class="nav-item">       
        <router-link   to="/login" exact-active-class="active">
          <a class="nav-link  " href="#">
            <i class='bx bxs-dashboard'></i> 
            Logout
          </a>
        </router-link>
      </li>  
  </ul>   
</nav>  