import Customer_navbar from '@/components/Customer/CustomerNavView.vue'
/* global $ */
import Swal from 'sweetalert2';
import { ref } from 'vue';

export default {
  name: 'CustomerDashView',
  components: {
    Customer_navbar,
  },
  setup() {
    const videos = ref([]); // Replace with your actual videos data source
    const showDetails = ref([]); // Array to keep track of which video's details are shown

    // Initialize showDetails array with false values
    const initializeShowDetails = () => {
      showDetails.value = new Array(videos.value.length).fill(false);
    };

    // Toggle the visibility of details for the clicked video
    const toggleDetails = (index) => {
      showDetails.value[index] = !showDetails.value[index];
    };

    const toggleOverlay = (index, isHovering) => {
      const overlay = document.querySelectorAll('.overlay')[index];
      if (!showDetails.value[index] && isHovering) {
        overlay.classList.add('bg-dark');
      } else {
        overlay.classList.remove('bg-dark');
      }
    };


    // Call initializeShowDetails when videos are set
    initializeShowDetails();
    return { 
      showBrandDropdown: false,
      tagInput: '',
      searchQuery: '',
      filteredBrands:[],
      videos,
      brands:[],
      products:[],
      filteredProducts:[],
      selectedProductIds: [],  // Initialize as an empty array
      selectedProducts: [],
      // showDetails: [],
      showDetails,
      toggleDetails,
      toggleOverlay
    };
  },
  methods: {
    async fetchVideos() {

      try {
        const token = localStorage.getItem('token');
        const FETCH_VIDEOS_URL = `${process.env.VUE_APP_API_BASE_URL}/brand_management/get_all_videos/`;

        const response = await $.ajax({
          url: FETCH_VIDEOS_URL,
          method: 'GET',
          headers: {
            'Authorization': `Token ${token}`
          }
        });

        const data = await response.json();
        this.videos = data; 
      } catch (error) {
        console.error('Error fetching videos:', error);
      }
    
  
    },
    uploadVideo() {
      $('.action-btn').on('click', async (e) => {
        e.preventDefault();
        
        Swal.fire({
          title: 'Uploading Video',
          text: 'Please wait while we upload your video...',
          allowOutsideClick: false,
          showConfirmButton: false,
          didOpen: () => {
              Swal.showLoading();
          }
        });

        const formData = new FormData();
        formData.append('caption', $('#caption').val());
        formData.append("brand_id", this.selectedBrandId);
        this.selectedProductIds.forEach(id => {
          formData.append('products', id);
        });
        formData.append('video_url', $('#video_url')[0].files[0]);

        const token = localStorage.getItem('token');
        const UPLOAD_VIDEO_URL = `${process.env.VUE_APP_API_BASE_URL}/influencer_management/upload_content/`;

        try {
          const response = await $.ajax({
            url: UPLOAD_VIDEO_URL,
            method: 'POST',
            body: formData,
            headers: {
              'Authorization': `Token ${token}`
            }
          });

          if (response.ok) {
            Swal.fire({
              icon: 'success',
              title: 'Upload Successful',
              text: 'Your video has been uploaded successfully!',
            }).then(() => {
              window.location.reload();
            });
          } else {
            throw new Error('Upload failed');
          }
        } catch (error) {
          Swal.fire({
            icon: 'error',
            title: 'Upload Failed',
            text: 'Failed to upload video. Please try again.',
          });
        }
      });
    },
  async deleteVideo(video_id) {
    const token = localStorage.getItem('token');

    Swal.fire({
      title: 'Are you sure?',
      text: "You won't be able to revert this!",
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#E39356',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Yes, delete it!'
    }).then(async (result) => {
      if (result.isConfirmed) {
        Swal.fire({
          title: 'Deleting Video',
          text: 'Please wait while the video is being deleted...',
          allowOutsideClick: false,
          showConfirmButton: false,
          didOpen: () => {
            Swal.showLoading();
          }
        });
        try {
          const DELETE_VIDEO_URL = `${process.env.VUE_APP_API_BASE_URL}/brand_management/delete_video/${video_id}`;
          const response = await $.ajax({
            url: DELETE_VIDEO_URL,
            method: 'DELETE',
            headers: {
              'Authorization': `Token ${token}`
            }
          });

          if (response.ok) {
            Swal.fire(
              'Deleted!',
              'The video has been deleted.',
              'success'
            ).then(() => {
              window.location.reload();
            });
          } else {
            throw new Error('Deletion failed');
          }
        } catch (error) {
          Swal.fire({
            icon: 'error',
            title: 'Deletion Failed',
            text: 'Failed to delete video. Please try again.',
          });
        }
      }
    });
    
  },
  async fetchBrands() {

    try {
      const token = localStorage.getItem('token');
      const FETCH_BRANDs_URL = `${process.env.VUE_APP_API_BASE_URL}/brand_management/get_all_brands/`;

      const response = await $.ajax({
        url: FETCH_BRANDs_URL,
        method: 'GET',
        headers: {
          'Authorization': `Token ${token}`
        }
      });

      const data = await response.json();
      this.brands = data;  // Assign the data to the videos property
    } catch (error) {
      console.error('Error fetching videos:', error);
    }
  

  },
  filterBrands() {
    if (this.tagInput.startsWith('@')) {
      this.showBrandDropdown = true;
      const query = this.tagInput.slice(1).toLowerCase(); // Remove '@' and make it lowercase
      this.filteredBrands = this.brands.filter(brand =>
        brand.brand_name.toLowerCase().includes(query)
      );
      this.showBrandDropdown = this.filteredBrands.length > 0;
    } else {
      this.showBrandDropdown = false;
    }
  },
  selectBrand(brand) {
    this.tagInput = `@${brand.brand_name}`;
    this.selectedBrandId = brand.id;
    this.showBrandDropdown = false;
    
  },
  async fetchProducts() {

    try {
      const token = localStorage.getItem('token');
      const FETCH_PRODUCTS_URL = `${process.env.VUE_APP_API_BASE_URL}/brand_management/get_all_products/`;

      const response = await $.ajax({
        url: FETCH_PRODUCTS_URL,
        method: 'GET',
        headers: {
          'Authorization': `Token ${token}`
        }
      });

      const data = await response.json();
      this.products = data;  // Assign the data to the videos property
    } catch (error) {
      console.error('Error fetching videos:', error);
    }
  },
  filterProducts() {
    const query = this.searchQuery.toLowerCase(); // Convert query to lowercase for case-insensitive search
    if (query) {
      this.filteredProducts = this.products.filter(product =>
        product.description.toLowerCase().includes(query)
      );
      this.showProductDropdown = this.filteredProducts.length > 0;
    } else {
      this.showProductDropdown = false;
    }
  },
  selectProduct(product) {
    // Initialize selectedProductIds if it's not already defined
    if (!this.selectedProductIds) {
      this.selectedProductIds = [];
    }
  
    // Check if the selected product is already in the array
    const isProductSelected = this.selectedProductIds.some(id => id === product.id);
  
    // If not selected, add to the list; otherwise, ignore
    if (!isProductSelected) {
      this.selectedProductIds.push(product.id);
      this.selectedProducts.push(product);
    }
  
    this.searchQuery = '';  // Clear input after selection
    this.showProductDropdown = false;  // Hide the dropdown after selection
  },

  removeProduct(product) {
    // Remove product from selectedProducts array
    this.selectedProducts = this.selectedProducts.filter(p => p !== product);
  },
  handleClickOutside(event) {
    const dropdown = this.$refs.dropdownContainer;
    if (dropdown && !dropdown.contains(event.target)) {
      this.showProductDropdown = false;
    }
  },
  

},

  mounted() {
    this.fetchVideos();
    this.fetchBrands();
    this.fetchProducts();
    this.handleClickOutside();
  },
  beforeDestroy() {
    this.handleClickOutside();
  }
  
};




