import Customer_navbar from '@/components/Customer/CustomerNavView.vue'
import Influencer_sidebar from '@/components/Influencer/Influencer_Sidebar/InfluencerSidebarView.vue';
import Swal from 'sweetalert2';
import $ from 'jquery'; 

export default {
  components: {
    Customer_navbar,
    Influencer_sidebar
  },
  data() {
    return {
      form: {
        first_name: '',
        last_name: '',
        email: '',
        phone_number: ''
      },
      videos: [],
      showEditProfileModal: false
    };
  },
  methods: {
    async fetchUserData() {
      const token = localStorage.getItem('token');
      if (!token) {
        Swal.fire({
          title: 'Error',
          text: 'You must be logged in to access this page.',
          icon: 'error',
          confirmButtonText: 'OK'
        }).then(() => {
          window.location.href = '/login';
        });
        return;
      }
      try {
        const FETCH_USER_DATA_URL = `${process.env.VUE_APP_API_BASE_URL}/system_management/user_info/`;

        const response = await $.ajax({
          url: FETCH_USER_DATA_URL,
          method: 'GET',
          headers: {
            'Authorization': `Token ${token}`
          }
        });

        if (!response.ok) {
          throw new Error('Failed to fetch user data');
        }

        const data = await response.json();
        localStorage.setItem('user_id', data.user_id);

        if (data.status === "success") {
        this.form.first_name = data.first_name;
        this.form.last_name = data.last_name;
        this.form.email = data.email;
        this.form.phone_number = data.phone_number;
        this.form.bio = data.bio || ''; 
        this.profileImage = data.profile_image_url; 
      } else {
        Swal.fire({
          title: 'Error',
          text: data.error || 'Failed to fetch brand data.',
          icon: 'error',
          confirmButtonText: 'OK'
        });
      }
      } catch (error) {
        console.error('Error fetching user data:', error);
      }
    },
    async updateUser() {
      const userData = {
        first_name: this.form.first_name,
        last_name: this.form.last_name,
        email: this.form.email,
        phone_number: this.form.phone_number || "",
        bio: this.form.bio,
        user_id: localStorage.getItem('user_id'),
      };

      try {
        const token = localStorage.getItem('token');
        const UPDATE_USER_URL = `${process.env.VUE_APP_API_BASE_URL}/influencer_management/update_profile/`;

        const response = await $.ajax({
          url: UPDATE_USER_URL,
          method: 'POST',
          headers: {
            'Authorization': `Token ${token}`,
            'Content-Type': 'application/json',

          },
          body: JSON.stringify(userData), 

        });
   
        if (!response.ok) {
          throw new Error('Failed to update user data');
        }
   
        const data = await response.json();
   
        if (data.status =="success") {
          Swal.fire({
            icon: 'success',
            text: data.message,
            title: 'Success',
            confirmButtonText: 'OK'
          }).then(() => {
            window.location.reload();
          });
        } else {
          throw new Error('Failed to update user data');
        }
   
   
      } catch (error) {
        console.error('Error updating user data:', error);
        Swal.fire({
          title: 'Error',
          text: 'Failed to update user data.',
          icon: 'error',
          confirmButtonText: 'OK'
        });
      }
    },
    async fetchVideos() {
      try {
        const token = localStorage.getItem('token');
        const FETCH_VIDEOS_URL = `${process.env.VUE_APP_API_BASE_URL}/influencer_management/view_content/`;
        const response = await $.ajax({
          url: FETCH_VIDEOS_URL,
          method: 'GET',
          headers: {
            'Authorization': `Token ${token}`
          }
        });
  
        const data = await response.json();
        this.videos = data; 
      } catch (error) {
        
        console.error('Error fetching videos:', error);
      }
    },
    triggerFileInput() {
      this.$refs.fileInput.click(); // Trigger file input click
    },
    previewImage(event) {
      const file = event.target.files[0];
      if (file) {
        const reader = new FileReader();
        reader.onload = (e) => {
          const img = document.getElementById('profileImage');
          if (img) {
            img.src = e.target.result; // Set the image source to the selected file
          }
        };
        reader.readAsDataURL(file);
      }
    },
    saveProfile() {
      // Handle saving the profile information here
      this.showEditProfileModal = false;
    }
},
  mounted() {
    this.fetchUserData();
    this.fetchVideos();
  }
};