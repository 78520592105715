<div class="home-navbar ">
  <div class="top-banner">
    Free delivery on orders over R10,000.00  
  </div>

  <nav class="first-navbar">
    <img :src="require('@/assets/images/new_logo_organge_black.png')" alt="Kyosk Logo" class=" logo">
    <div class="search-bar">
        <input type="text" placeholder="Search for items and brands" id="search-input">
        <div class="search-dropdown" id="search-dropdown">
            <a href="#">Popular search 1</a>
            <a href="#">Popular search 2</a>
            <a href="#">Popular search 3</a>
            <a href="#">Popular search 4</a>
            <a href="#">Popular search 5</a>
        </div>
    </div>
    <div class="nav-icons">
        <div class="icon-container" data-dropdown>
            <a href="#"><i class='bx bx-user'></i></a>
            <div class="dropdown-content">
                <a href="#">My Account</a>
                <a href="#">Orders</a>
                <a href="#">Wishlist</a>
                <router-link to="/register" class="">
                  <span class="text-dark">Sign Up</span>
                </router-link> 
        
                <router-link to="/login" class=" ">
                  <span class="text-dark">Login</span>
                </router-link> 
            </div>
        </div> 
        <div class="icon-container" data-dropdown>
            <a href="#"><i class='bx bx-shopping-bag'></i></a> 
        </div>
    </div>
  </nav>

  <nav class="second-navbar">
    <div class="nav-item" data-dropdown>
        <a href="#">NEW IN</a>
        <div class="dropdown-content">
            <a href="#">Women</a>
            <a href="#">Men</a>
            <a href="#">Kids</a>
        </div>
    </div>
    <div class="nav-item" data-dropdown>
        <a href="#">BRANDS</a>
        <div class="dropdown-content">
            <a href="#">Top Brands</a>
            <a href="#">Local Brands</a>
            <a href="#">International Brands</a>
        </div>
    </div>
    <div class="nav-item" data-dropdown>
        <a href="#">WOMEN</a>
        <div class="dropdown-content">
            <a href="#">Clothing</a>
            <a href="#">Shoes</a>
            <a href="#">Accessories</a>
        </div>
    </div>
    <div class="nav-item" data-dropdown>
        <a href="#">MEN</a>
        <div class="dropdown-content">
            <a href="#">Clothing</a>
            <a href="#">Shoes</a>
            <a href="#">Accessories</a>
        </div>
    </div>
    <div class="nav-item" data-dropdown>
        <a href="#">KIDS</a>
        <div class="dropdown-content">
            <a href="#">Girls</a>
            <a href="#">Boys</a>
            <a href="#">Babies</a>
        </div>
    </div>
    <div class="nav-item" data-dropdown>
        <a href="#">BEAUTY</a>
        <div class="dropdown-content">
            <a href="#">Makeup</a>
            <a href="#">Skincare</a>
            <a href="#">Fragrance</a>
        </div>
    </div>
    <div class="nav-item" data-dropdown>
        <a href="#">HOME</a>
        <div class="dropdown-content">
            <a href="#">Decor</a>
            <a href="#">Furniture</a>
            <a href="#">Kitchenware</a>
        </div>
    </div>
    <div class="nav-item" data-dropdown>
        <a href="#">SALE</a>
        <div class="dropdown-content">
            <a href="#">Women's Sale</a>
            <a href="#">Men's Sale</a>
            <a href="#">Kids' Sale</a>
        </div>
    </div>
  </nav>
</div>