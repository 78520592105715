<nav id="sidebar" class="sidebar">
  <div class=" d-flex align-items-start text-start">
      <div>
        <strong>Brand-Admin</strong> 
    </div>
  </div>
  
  <ul class="nav flex-column">
      <li class="nav-item">       
        <router-link to="/dashboard" exact-active-class="active">
          <a class="nav-link" href="#">
            <i class='bx bxs-dashboard'></i> 
            Home
          </a>
        </router-link>
      </li>
      <li class="nav-item">       
        <router-link  to="/content_upload" exact-active-class="active">
          <a class="nav-link" href="#">
            <i class='bx bxs-dashboard'></i> 
            Content Uploads
          </a>
        </router-link>
      </li>
      <li class="nav-item">       
        <router-link to="/inventory" exact-active-class="active">
          <a class="nav-link " href="#">
            <i class='bx bxs-dashboard'></i> 
            Inventory
          </a>
        </router-link>
      </li>
      <li class="nav-item">       
        <router-link  to="/order" exact-active-class="active">
          <a class="nav-link " href="#">
            <i class='bx bxs-dashboard'></i> 
           Order
          </a>
        </router-link>
      </li>
      <li class="nav-item">       
        <router-link to="/add_user"  exact-active-class="active">
          <a class="nav-link  " href="#">
            <i class='bx bxs-dashboard'></i> 
            Team
          </a>
        </router-link>
      </li>
      <li class="nav-item">       
        <router-link   to="/analytics" exact-active-class="active">
          <a class="nav-link  " href="#">
            <i class='bx bxs-dashboard'></i> 
            Analytics
          </a>
        </router-link>
      </li> 
      <li class="nav-item">       
        <router-link   to="/login" exact-active-class="active">
          <a class="nav-link  " href="#">
            <i class='bx bxs-dashboard'></i> 
            Logout
          </a>
        </router-link>
      </li> 
  </ul>  

</nav>  