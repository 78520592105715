 <!-- Main Content -->
<div class=" ">
    <!-- Include Navbar -->
    <Influencer_navbar />
    <div class="container-fluid mt-5">
      <div class="hero">
        <div class="video-container rounded-12 border-0">
          <div class="fade-overlay"></div>

            <video autoplay muted loop>
                <source :src="require('@/assets/images/istockphoto-1828624814-640_adpp_is.mp4')" type="video/mp4" >
                Your browser does not support the video tag.
            </video>
        </div>
        <div class="hero-content text-start text-light">
          <div class="d-flex align-content-center">
            <img :src="require('@/assets/images/orange-icon-logo.png')" alt="Kyosk Logo" class="hero-logo">
            <h3 class="ms-2">Influencer</h3>
          </div>
            <p>SHOP | PRODUCTS | LIFESTYLE</p>
            <p>2024 | COLLECTION:</p>
            <button class="btn btn-light text-dark"> <i class='bx bx-shopping-bag me-1'></i>View Products</button>
            <button class="btn btn-outline-warning ms-3"> <i class='bx bxs-credit-card me-1'></i>More Brands</button>
        </div>
        <div class="hero-cards  d-none">
            <div class="container">
                <div class="sub-scroller">
                  <div class="card bg-dark text-white">
                      <img src="https://via.placeholder.com/50x50" class="card-img-top" alt="Episode 1">
                      <div class="card-body">
                          <h5 class="card-title">Episode 1</h5>
                      </div>
                  </div>
                  <div class="card bg-dark text-white">
                      <img src="https://via.placeholder.com/200x300" class="card-img-top" alt="Episode 2">
                      <div class="card-body">
                          <h5 class="card-title">Episode 2</h5>
                      </div>
                  </div>
                  <div class="card bg-dark text-white">
                      <img src="https://via.placeholder.com/200x300" class="card-img-top" alt="Episode 3">
                      <div class="card-body">
                          <h5 class="card-title">Episode 3</h5>
                      </div>
                </div> 
              </div>
            </div>
        </div>
      </div>
 
      <br>  
      <h3 class="mt-4">New Collection </h3>  
      
      <div class="  px-5 d-flow-root subvideo-content mb-5 ">
    
        <div class="d-flex justify-content-center d-none">
          <hr class="w-25 text-center">
        </div>
        <div class="scroller-container">
          <div class="scroller">
              <div class="card-container" :style="{ transform: `translateX(-${currentPosition * (100 / visibleCards)}%)` }">
              <div v-for="(video, index) in videos" :key="index" class="card">
                  <!-- Your video content here -->
                  <video :src="video.video_url" class=" rounded-12" :alt="video.description" >
                  <h4><b>{{ video.title }}</b></h4>
                  </video> 
                  <p>{{ video.description }}</p>
                  <!-- Add more video details as needed -->
              </div>
              </div>
          </div>
            <button class="scroll-btn left" @click="scrollCards('left')"><i class='bx bxs-chevron-left'></i></button>
            <button class="scroll-btn right" @click="scrollCards('right')"><i class='bx bxs-chevron-right'></i></button>
          </div> 

        </div>  
      </div>
    
    <div  id="navbar-placeholder">
      <div class="container mt-307 d-none">
        
        <div v-if="videos.length === 0" class="text-center my-4">Loading videos...</div>
        <div v-else>
          <!-- Your existing code -->
          <div v-if="videos.length > 0" class="row mb-4">
            <div class="col-12">
              <div class="  media-body">
                <div class="position-relative">
                  <!-- Full-screen Video element -->
                  <video :src="videos[0].video_url" autoplay muted loop controls class="h-75 w-75 object-fit-cover rounded-12">
                    Your browser does not support the video tag.
                  </video>
                </div>
              </div>
            </div>
          </div>
          
        
            <!-- Mini videos grid -->
            <div class="row">
              <div v-for="(video, index) in videos" :key="video.id" class="col-lg-4 col-md-6 mb-4">
                <div class="card shadow-sm">
                  <div class="position-relative">
                    <!-- Mini Video element -->
                    <video :src="video.video_url" controls class="card-img-top">
                      Your browser does not support the video tag.
                    </video>
                    <!-- Caption overlay, visible on hover -->
                    <div class="overlay position-absolute bottom-0 start-0 m-2">
                      <h5 class="d-flex justify-content-start text-white p-2 caption-overlay" @click="toggleDetails(index + 1)">
                        {{ video.caption }}
                      </h5>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
</div>
</div>



<div class="modal fade" id="uploadModal" tabindex="-1" role="dialog" aria-labelledby="uploadModalLabel" aria-hidden="true">
    <div class="modal-dialog modal-lg" role="document">
        <div class="modal-content">
            <div class="modal-header">
                <h5 class="modal-title" id="uploadModalLabel">Video Upload</h5>
                <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                    <span aria-hidden="true">&times;</span>
                </button>
            </div>
            <div class="modal-body">
                <div class="row">
                    <!-- Video Section -->
                    <div class="col-md-6">
                      <div class="upload-box">
                        <label for="videoUpload" class="upload-label" id="uploadLabel">
                          <div class="upload-image-icon" id="uploadIcon">📤</div>
                          <p id="uploadText">Click to upload file</p>
                          <video id="videoPreview" controls class="video-preview"></video>
                        </label>
                        <input type="file" id="video_url" accept="video/*" class="file-inputs" @change="handleFileChange">
                      </div>
                    </div>
                  
                    <!-- Form Section -->
                    <div class="col-md-6">
                      <div class="form-group">
                        <label for="caption">Caption:</label>
                        <input type="text" v-model="caption" id="caption" class="form-control form-control-sm">
                      </div>
                    <div class="form-group position-relative">
                        <label for="brand">Tag Brand:</label>
                        <input type="text" v-model="tagInput" id="tagInput" class="form-control" placeholder="Type @ to tag brands" @keyup="filterBrands">
                        <!-- Dropdown for brands -->
                        <ul v-if="showBrandDropdown" class="dropdown-menu show">
                          <li v-for="brand in filteredBrands" :key="brand.id" @click="selectBrand(brand)" class="dropdown-item">
                            {{ brand.brand_name }}
                          </li>
                        </ul>
                      </div>

                      <div class="form-group position-relative">
                        <label for="productSearch">Search Products:</label>
                        <input type="text" v-model="searchQuery" id="products" class="form-control" placeholder="Type product name" @keyup="filterProducts">
                        <!-- Dropdown for products -->
                        <ul v-if="showProductDropdown" class="dropdown-menu show">
                          <li v-for="product in filteredProducts" :key="product.id" @click="selectProduct(product)" class="dropdown-item d-flex align-items-center">
                            <img :src="product.product.image_url" alt="Image" class="product-image-thumbnail mr-2">
                            <span>{{ product.product.product_name }}</span>
                          </li>
                        </ul>
                      </div>
                      
                      <!-- Display selected products -->
                      <div v-if="selectedProducts.length > 0">
                        <h5>Selected Products:</h5>
                        <ul>
                          <li v-for="product in selectedProducts" :key="product.id" class="d-flex align-items-center">
                            <img :src="product.image_url" alt="Image" class="product-image-thumbnail mr-2">
                            <span>{{ product.description }}</span>
                            <span @click="removeProduct(product)" class="ml-2 text-danger" style="cursor: pointer;">&times;</span>
                        </li>
                        </ul>
                      </div>
                      
                      </div>
                      <br>
            
                    <div class="modal-footer">
                        <button type="submit" class="action-btn" @click="uploadVideo">Upload</button>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>

  