<div class="home-navbar ">
    <nav class="influencer-top-nav navbar navbar-expand-lg navbar-dark bg-dark z-3">
        <div class="container-fluid">
            <img :src="require('@/assets/images/new-logo-white-orange.png')" alt="Kyosk Logo" class=" logo">
    
            <button class="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#navbarNav" aria-controls="navbarNav" aria-expanded="false" aria-label="Toggle navigation">
                <span class="navbar-toggler-icon"></span>
            </button>
            <div class="collapse navbar-collapse" id="navbarNav">
                <ul class="navbar-nav">
                    <li class="nav-item">
                        <router-link to="/Customer_dashboard">
                            Home
                        </router-link> 
                    </li> 
                    <li class="nav-item">
                        <router-link to="/Customer_profile">
                            Profile 
                        </router-link>                  
                    </li> 
                </ul>
            </div>
            <div class="d-flex align-items-center">
                <i class="bx bx-search nav-icon text-white me-3" id="search_icon"></i>      
                <i class='bx bx-shopping-bag nav-icon text-white me-2'></i>        
                <router-link to="/login" exact-active-class="active">
                    <i class="bx bx-log-in nav-icon text-white"></i>  
                </router-link>            
            </div>
        </div>
    </nav>
    <div class="search-input bg-dark p-2 d-none" id="search_input">
        <div class="container-fluid">
            <input type="text" class="form-control" placeholder="Search...">
        </div>
    </div>

    <nav class="second-navbar">
        <div class="nav-item" data-dropdown>
            <a href="#">NEW IN</a>
            <div class="dropdown-content">
                <a href="#">Women</a>
                <a href="#">Men</a>
                <a href="#">Kids</a>
            </div>
        </div>
        <div class="nav-item" data-dropdown>
            <a href="#">BRANDS</a>
            <div class="dropdown-content">
                <a href="#">Top Brands</a>
                <a href="#">Local Brands</a>
                <a href="#">International Brands</a>
            </div>
        </div>
        <div class="nav-item" data-dropdown>
            <a href="#">WOMEN</a>
            <div class="dropdown-content">
                <a href="#">Clothing</a>
                <a href="#">Shoes</a>
                <a href="#">Accessories</a>
            </div>
        </div>
        <div class="nav-item" data-dropdown>
            <a href="#">MEN</a>
            <div class="dropdown-content">
                <a href="#">Clothing</a>
                <a href="#">Shoes</a>
                <a href="#">Accessories</a>
            </div>
        </div>
        <div class="nav-item" data-dropdown>
            <a href="#">KIDS</a>
            <div class="dropdown-content">
                <a href="#">Girls</a>
                <a href="#">Boys</a>
                <a href="#">Babies</a>
            </div>
        </div>
        <div class="nav-item" data-dropdown>
            <a href="#">BEAUTY</a>
            <div class="dropdown-content">
                <a href="#">Makeup</a>
                <a href="#">Skincare</a>
                <a href="#">Fragrance</a>
            </div>
        </div>
        <div class="nav-item" data-dropdown>
            <a href="#">HOME</a>
            <div class="dropdown-content">
                <a href="#">Decor</a>
                <a href="#">Furniture</a>
                <a href="#">Kitchenware</a>
            </div>
        </div>
        <div class="nav-item" data-dropdown>
            <a href="#">SALE</a>
            <div class="dropdown-content">
                <a href="#">Women's Sale</a>
                <a href="#">Men's Sale</a>
                <a href="#">Kids' Sale</a>
            </div>
        </div>
    </nav>
</div>