<div class="home-navbar ">
    <nav class="influencer-top-nav navbar navbar-expand-lg navbar-dark bg-dark z-3">
        <div class="container-fluid">
            <img :src="require('@/assets/images/new-logo-white-orange.png')" alt="Kyosk Logo" class=" logo">

            <button class="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#navbarNav" aria-controls="navbarNav" aria-expanded="false" aria-label="Toggle navigation">
                <span class="navbar-toggler-icon"></span>
            </button>
            <div class="collapse navbar-collapse" id="navbarNav">
            
            </div>
            <div class="d-flex align-items-center">
                <i class="bx bx-search nav-icon text-white me-3" id="search_icon"></i>             
                <router-link to="/login" exact-active-class="active">
                    <i class="bx bx-log-in nav-icon text-white"></i>  
                </router-link>            
            </div>
        </div>
    </nav>
    <div class="search-input bg-dark p-2 d-none" id="search_input">
        <div class="container-fluid">
            <input type="text" class="form-control" placeholder="Search...">
        </div>
    </div> 
</div>