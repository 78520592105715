/* global $ */
import Sidebar from '@/components/Brand/Sidebar/SideView.vue';
import Navbar from '@/components/Brand/Navbar/NavbarView.vue';
import Swal from 'sweetalert2';

export default {
  name: 'TeamView',
  components: {
    Sidebar,
    Navbar,
  },
  data() {
    return {
      dataTable: null,
      form: {
        first_name: '',
        last_name: '',
        email: '',
        role: '',
      },
      users: [],

    };
  },

  mounted() {
    this.fetchUsers();
  },
  
  methods: {
    
    
    
    async submitForm() {
      const token = localStorage.getItem('token');
      const FETCH_ADD_USER_URL = `${process.env.VUE_APP_API_BASE_URL}/system_management/add_users/`;
  
      try {
        const response = await $.ajax({
          url: FETCH_ADD_USER_URL,
          method: 'POST',
          headers: {
            'Authorization': `Token ${token}`,
            'Content-Type': 'application/json'
          },
          data: JSON.stringify(this.form),
          dataType: 'json',
        });
       
  
        if (response.status =="success") {
          Swal.fire({
            icon: 'success',
            text: response.message,
            title: 'Success',
            confirmButtonText: 'OK'
          }).then(() => {
            window.location.reload();
          });
        } else {
          throw new Error('Failed to add user.');
        }

      } catch (error) {
        const errorMessage = error.responseJSON?.message
        ? typeof error.responseJSON.message === 'object'
          ? Object.values(error.responseJSON.message).flat().join(' ')
          : error.responseJSON.message
        : error.message || 'An error occurred.';

      console.error(error);
      await Swal.fire({
        title: 'Error',
        text: errorMessage,
        icon: 'error',
        confirmButtonText: 'OK'
        });
      }
    },
    initializeDataTable() {
      this.$nextTick(() => {
        if ($.fn.DataTable.isDataTable('#content_table')) {
          $('#content_table').DataTable().destroy();
        }
        this.dataTable = $('#content_table').DataTable({
          pageLength: 10,
          lengthChange: false,
          searching: true,
          ordering: true,
          paging: true,
          info: true,
          autoWidth: false,
          
        });
      });
    },
    async fetchUsers() {
      const token = localStorage.getItem('token');
      const FETCH_USERS_URL = `${process.env.VUE_APP_API_BASE_URL}/system_management/get_users/`;

      try {
        const response = await $.ajax({
          url: FETCH_USERS_URL,
          method: 'GET',
          headers: {
            'Authorization': `Token ${token}`,
            'Content-Type': 'application/json'
          },
          dataType: 'json',
        });
        

        this.users = response;
        this.initializeDataTable();
      
        
      } catch (error) {
        console.error(error);
        Swal.fire({
          title: 'Error',
          text: 'Failed to fetch users.',
          icon: 'error',
          confirmButtonText: 'OK'
        });
      }
    },
  
    resetForm() {
      this.form = {
        first_name: '',
        last_name: '',
        email: '',
        role: '',
      };
    },

    editUser(user) {
      var editModal = $('#editUserModal');
      editModal.show();
      this.form = {
        user_id: user.id,
        first_name: user.first_name,
        last_name: user.last_name,
        email: user.email,
        role: user.role
      };
    },
  

  
    async updateUser() {
      const token = localStorage.getItem('token');
         const formData = new FormData();
      for (const key in this.form) {
        formData.append(key, this.form[key]);
      }
      const UPDATE_USER_URL = `${process.env.VUE_APP_API_BASE_URL}/system_management/get_user_info/`;

      try {
        const response = await $.ajax({
          url: UPDATE_USER_URL,
          method: 'POST',
          headers: {
            'Authorization': `Token ${token}`,
          },
          body: formData
        });

   
        if (!response.ok) {
          throw new Error('Failed to update user data');
        }
   
        const data = await response.json();
   
        if (data.status =="success") {
          Swal.fire({
            icon: 'success',
            text: data.message,
            title: 'Success',
            confirmButtonText: 'OK'
          }).then(() => {
            window.location.reload();
          });
        } else {
          throw new Error('Failed to update user data');
        }
   
      
      await this.fetchUsers();

      var editModal = $('#editUserModal');
      editModal.hide();

   
      } catch (error) {
        console.error('Error updating user data:', error);
        Swal.fire({
          title: 'Error',
          text: 'Failed to update user data.',
          icon: 'error',
          confirmButtonText: 'OK'
        });
      }
    },
    async deactivateUser(user) {      
      const token = localStorage.getItem('token');
      const CHANGE_USER_STATUS_URL = `${process.env.VUE_APP_API_BASE_URL}/brand_management/change_user_status/`;
      const newStatus = user.is_active ? 'f' : 't';
      const action = user.is_active ? 'deactivated' : 'activated'; 

      try {
     
        await $.ajax({
          url: CHANGE_USER_STATUS_URL,
          method: 'POST',
          headers: {
            'Authorization': `Token ${token}`,
            'Content-Type': 'application/json'
          },
          data: JSON.stringify({
            user_id: user.id,
            is_active: newStatus
          }),
          dataType: 'json',
        });
    
        Swal.fire({
          title: 'Success',
          text: `User ${user.first_name} ${user.last_name} ${action} successfully.`,
          icon: 'success',
          confirmButtonText: 'OK'
        });
    
        await this.fetchUsers();
      } catch (error) {
        console.error(error);
        Swal.fire({
          title: 'Error',
          text: `Failed to ${action} user ${user.first_name} ${user.last_name}.`,
          icon: 'error',
          confirmButtonText: 'OK'
        });
      }
    }
  }
};

$(document).ready(function() {
  var uploadBtn = $('.action-btn');
  var modal = $('#uploadModal');
  var Editmodal = $('#editUserModal');
  var createBtn = $('.create-btn');
  var closeBtn = $('.close-btn');

 
  uploadBtn.on('click', function() {
    modal.show();
  });

  createBtn.on('click', function() {
    modal.hide();
  });
 
  closeBtn.on('click', function() {
    modal.hide();    
    Editmodal.hide();

  });
  
 
  $(window).on('click', function(event) {
    if ($(event.target).is(modal)) {
      modal.hide();
    }
  });
});
 