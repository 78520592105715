<div class="side-panel left"></div>
<div class="side-panel right"></div>

<div id="app">
  <b-container>
    <b-row>
      <b-col>
    <img :src="require('@/assets/images/Kyosk CI-11.png')" alt="Kyosk Logo" class="kyosk-logo">
        <b-form @submit.prevent="onSubmit" class="login-form">
          <b-row>
            <button class="google-login">
              <img src="https://developers.google.com/identity/images/g-logo.png" alt="Google Logo" class="google-logo">
              Login with Google
            </button>
          </b-row>
          <div class="divider">
            <span>Or</span>
          </div>
          <b-form-group id="emailGroup" label="Email Address:" label-for="emailInput">
            <b-form-input type="email" id="email" v-model="form.email" required></b-form-input>
          </b-form-group>
          <b-form-group id="passwordGroup" label="Password:" label-for="passwordInput">
            <b-form-input type="password" id="password" v-model="form.password" required></b-form-input>
          </b-form-group>
          <button type="submit" variant="primary" class="login-button">Login</button>
        </b-form>
      </b-col>

      <b-row>
        <b-col md="6">
          <p class="mt-3">
            <a> Sign Up as:</a>
          </p>
        </b-col>
        <b-col md="6">
          <b-link @click.prevent="setRoleAndRedirect('brand')" href="#">Brand</b-link>
        </b-col>
        
        <b-col md="6">
          <b-link @click.prevent="setRoleAndRedirect('influencer')" href="#">Influencer</b-link>
        </b-col>
        <b-col md="6">
          <b-link @click.prevent="setRoleAndRedirect('customer')" href="#">Customer</b-link>
        </b-col>
          
        <b-col md="6">
          <b-link href="/forgot-password" class="forgot-password-link">Forgot Password?</b-link>

        </b-col>
      </b-row>

    </b-row>
  </b-container>
</div>
