 
<Customer_navbar />
 
<div class="container-fluid  mt-5">
  <div class="hero">
    <div class="video-container rounded-12 border-0">
      <div class="fade-overlay"></div>

        <video autoplay muted loop>
            <source :src="require('@/assets/images/istockphoto-1828624814-640_adpp_is.mp4')" type="video/mp4" >
            Your browser does not support the video tag.
        </video>
    </div>
    <div class="hero-content text-start text-light">
      <div class="d-flex align-content-center">
        <img :src="require('@/assets/images/orange-icon-logo.png')" alt="Kyosk Logo" class="hero-logo">
        <h3 class="ms-2">Customer</h3>
      </div>
        <p>SHOP | PRODUCTS | LIFESTYLE</p>
        <p>2024 | COLLECTION:</p>
        <button class="btn btn-light text-dark"> <i class='bx bx-shopping-bag me-1'></i>View Products</button>
        <button class="btn btn-outline-warning ms-3"> <i class='bx bxs-credit-card me-1'></i>More Brands</button>
    </div>
    <div class="hero-cards  d-none">
        <div class="container">
            <div class="sub-scroller">
              <div class="card bg-dark text-white">
                  <img src="https://via.placeholder.com/50x50" class="card-img-top" alt="Episode 1">
                  <div class="card-body">
                      <h5 class="card-title">Episode 1</h5>
                  </div>
              </div>
              <div class="card bg-dark text-white">
                  <img src="https://via.placeholder.com/200x300" class="card-img-top" alt="Episode 2">
                  <div class="card-body">
                      <h5 class="card-title">Episode 2</h5>
                  </div>
              </div>
              <div class="card bg-dark text-white">
                  <img src="https://via.placeholder.com/200x300" class="card-img-top" alt="Episode 3">
                  <div class="card-body">
                      <h5 class="card-title">Episode 3</h5>
                  </div>
            </div> 
          </div>
        </div>
    </div>
  </div>
  <br>
  <h3 class="mt-4">New Collection </h3>  
  <div class="  px-5 d-flow-root subvideo-content mb-5 ">

    <div class="d-flex justify-content-center d-none">
      <hr class="w-25 text-center">
    </div>
    <div class="scroller-container">
      <div class="scroller">
          <div class="card-container" :style="{ transform: `translateX(-${currentPosition * (100 / visibleCards)}%)` }">
          <div v-for="(video, index) in videos" :key="index" class="card">
              <!-- Your video content here -->
              <video :src="video.video_url" class=" rounded-12" :alt="video.description" >
              <h4><b>{{ video.title }}</b></h4>
              </video> 
              <p>{{ video.description }}</p>
              <!-- Add more video details as needed -->
          </div>
          </div>
      </div>
        <button class="scroll-btn left" @click="scrollCards('left')"><i class='bx bxs-chevron-left'></i></button>
        <button class="scroll-btn right" @click="scrollCards('right')"><i class='bx bxs-chevron-right'></i></button>
      </div>  
    </div>  
  </div>  
 