
<div class="side-panel left"></div>
<div class="side-panel right"></div>

<div id="app">
  <b-container>
    <b-row>
      <b-col>
        <b-alert v-if="errorMessage" show variant="danger">{{ errorMessage }}</b-alert>
        <b-alert v-if="successMessage" show variant="success">{{ successMessage }}</b-alert>

        <img :src="require('@/assets/images/Kyosk CI-11.png')" alt="Kyosk Logo" class="kyosk-logo">
        <b-form id="register_form" @submit.prevent="onSubmit" class="register-form">  
          <b-row>
            <button class="google-login">
              <img src="https://developers.google.com/identity/images/g-logo.png" alt="Google Logo" class="google-logo">
              Register with Google
            </button>
          </b-row>
          <b-row>
            <div class="divider">
              <span>Or</span>
            </div>
          </b-row>

          <b-row>
            <b-col md="6">
              <b-form-group id="firstNameGroup" label="First name:" label-for="firstNameInput">
                <b-form-input id="first_name" v-model="form.first_name" required></b-form-input>
              </b-form-group>
            </b-col>
            <b-col md="6">
              <b-form-group id="lastNameGroup" label="Last name:" label-for="lastNameInput">
                <b-form-input id="last_name" v-model="form.last_name" required></b-form-input>
              </b-form-group>
            </b-col>
          </b-row>
          <b-form-group id="emailGroup" label="Email Address:" label-for="emailInput">
            <b-form-input type="email" id="email" v-model="form.email" required></b-form-input>
          </b-form-group>
          <b-form-group v-if="isBrand" id="brandNameGroup" label="Brand Name:" label-for="brandNameInput">
            <b-form-input id="brand_name" v-model="form.brand_name" required></b-form-input>
          </b-form-group>

          <b-form-group v-else id="phoneNumberGroup" label="Phone Number:" label-for="phoneNumberInput">
            <b-form-input type="tel" id="phone_number" v-model="form.phone_number" required></b-form-input>
          </b-form-group>

          <b-form-group id="passwordGroup" label="Password:" label-for="passwordInput">
            <b-form-input type="password" id="passwordInput" v-model="form.password" required></b-form-input>
          </b-form-group>
          <b-form-group id="confirmPasswordGroup" label="Confirm Password:" label-for="confirmPasswordInput">
            <b-form-input type="password" id="confirm_password" v-model="form.confirm_password" required></b-form-input>
            <small id="password-requirements" class="text-blue d-none">
              Password must be at least 8 characters long and contain:<br>
              <span id="length-requirement" > At least 8 characters</span><br>
               <span id="uppercase-requirement" > At least one uppercase letter</span><br>
              <span id="lowercase-requirement" > At least one lowercase letter</span><br>
               <span id="digit-requirement" > At least one digit</span><br>
              <span id="specialchar-requirement" > At least one special character</span><br>
          </small>
          </b-form-group>
          <b-form-group>
            <b-form-checkbox v-model="form.agree" required>
              Creating an account means you're okay with our Terms of Service, Privacy Policy, and our default Notification Settings.
            </b-form-checkbox>
          </b-form-group>
          <button type="submit" id ="register" variant="primary" class="register-button">Sign Up</button>
        </b-form>

        <p class="mt-3">
          Already a member? <a href="/login">Login</a>
        </p>
        
      </b-col>
    </b-row>
  </b-container>
</div>

