 <Customer_navbar />
 
<Influencer_sidebar/>

<div class=" app-content">
    
    <div class="profile-header">
        <div class="profile-picture">
            <div class="profile-image-wrapper">
                <img id="profileImage" src="" alt="Profile Picture">
                <div class="upload-icon" @click="triggerFileInput" id="uploadIcon">
                    <i class="fas fa-camera"></i>
                </div>
            </div>
            <input ref="fileInput" type="file" accept="image/*" @change="previewImage" style="display: none;">
        </div>
        <div class="profile-info">
            <h3>{{ form.first_name }}</h3>
            <h3>{{ form.last_name }}</h3>
        </div>
        <div class="profile-actions">
            <button class="action-btn"  @click="showEditProfileModal = true">Edit Profile</button>
        </div>
    </div>
    <div class="profile-tabs">
        <button class="tab-btn active"> Wishlist</button>
        <button class="tab-btn">Track Order</button>
    </div>
    <div class="profile-content">
        <div v-if="videos.length === 0" class="text-center my-4">Loading videos...</div>
        <div v-else class="row">
          <div class="col-lg-4 col-md-6 mb-4" v-for="video in videos" :key="video.id">
            <div class="card shadow-sm">
              <div class="position-relative">
                <!-- Video element -->
                <video :src="video.video_url" controls class="card-img-top w-100">
                  Your browser does not support the video tag.
                </video>
      
                <!-- Overlay with video details -->
                <div class="overlay position-absolute bottom-0 start-0 m-2">
                  <h5 class="d-flex justify-content-start text-white p-2">{{ video.caption }}</h5>
                  <div class="d-flex align-items-center mt-2 bg-dark p-2 rounded  custom-width">
                    <img :src="video.products_image_url" alt="video.caption" class="img-thumbnail me-2">
                    <div class="align-items">
                      <h6 class="description text-white mb-0">{{ video.products_description }}</h6>
                      <p class="text-warning mb-0">R{{ video.products_price }}</p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
        </div>
  
        <div v-if="showEditProfileModal" class="modal fade show" tabindex="-1" role="dialog" style="display: block; background-color: rgba(0, 0, 0, 0.5);">
            <div class="modal-dialog modal-dialog-centered" role="document">
              <div class="modal-content">
                <div class="modal-header">
                  <h5 class="modal-title">Profile</h5>
                  <button type="button" class="close" @click="showEditProfileModal = false" aria-label="Close">
                    <span aria-hidden="true">&times;</span>
                  </button>
                </div>
                <div class="modal-body">
                  <div class="d-flex justify-content-center mb-3">
                    <div class="profile-picture">
                      <div class="profile-image-wrapper">
                        <img id="profileImage" :src="profileImage" alt="Profile Picture" class="rounded-circle">
                        <div class="upload-icon" @click="triggerFileInput" id="uploadIcon">
                          <i class="fas fa-camera"></i>
                        </div>
                      </div>
                      <input ref="fileInput" type="file" accept="image/*" @change="previewImage" style="display: none;">
                    </div>
                  </div>
                    <form @submit.prevent="updateUser">
    
                    <div class="row">
                      <div class="col-md-6">
                        <div class="form-group">
                          <label for="first_name" class="text-label">First name:</label>
                          <input type="text" class="form-control" v-model="form.first_name" id="first_name">
                        </div>
                      </div>
                      <div class="col-md-6">
                        <div class="form-group">
                          <label for="last_name" class="text-label">Last name:</label>
                          <input type="text" class="form-control" v-model="form.last_name" id="last_name">
                        </div>
                      </div>
                    </div>
                    <div class="row">
                      <div class="col-md-6">
                        <div class="form-group">
                          <label for="email" class="text-label">Email address:</label>
                          <input type="email" class="form-control" v-model="form.email" id="email">
                        </div>
                      </div>
                      <div class="col-md-6">
                        <div class="form-group">
                          <label for="phone_number" class="text-label">Phone number:</label>
                          <input type="text" class="form-control" v-model="form.phone_number" id="phone_number">
                        </div>
                      </div>
                    </div>
                  </form>
                </div>
                <div class="modal-footer">
                    <button type="button" class="action-btn" @click="updateUser">Save changes</button>
                </div>
              </div>
            </div>
          </div>
