/* global $ */
import Sidebar from '@/components/Brand/Sidebar/SideView.vue';
import Navbar from '@/components/Brand/Navbar/NavbarView.vue';
import axios from 'axios';
import Swal from 'sweetalert2';


export default {
  name: 'ManageView',
  components: {
    Sidebar,
    Navbar
  },
  data() {
    return {
      image_files: [],
        currentPage: 0,
        imagesPerPage: 5,
        current_image: {
          id: null,
          description: '',
          price: '',
          no_of_items: '',
          categories: [],
          selectedCategory: '',
          newCategory: '',
          size:'',
          color:'',
          quantity:'',
          product_name:'',

        }
    };
  },
  mounted() {
    this.loadVideo();
    this.initImageUpload();
    this.loadImages();
    this.fetchCategories();
    
  },

  methods: {
    scrollLeft() {
      const container = this.$refs.imageContainer.querySelector('.row');
      container.scrollBy({
        left: -container.offsetWidth,
        behavior: 'smooth'
      });
    },
    scrollRight() {
      const container = this.$refs.imageContainer.querySelector('.row');
      container.scrollBy({
        left: container.offsetWidth,
        behavior: 'smooth'
      });
    },
      loadVideo() {
          const video_id = this.$route.params.video_id;
          if (video_id) {
              const token = localStorage.getItem('token');
              const LOAD_VIDEO_URL = `${process.env.VUE_APP_API_BASE_URL}/brand_management/view_video/${video_id}`;
            
              axios.get(LOAD_VIDEO_URL, {
                  headers: {
                    'Authorization': `Token ${token}` 
                  }
              })
              .then(response => {
                  const video_url = response.data.video_url;
                  $('#video_source').attr('src', video_url);
                  $('#video_player')[0].load();
              })
              .catch(error => {
                console.error('Error loading video:', error);
              });
          } else {
              Swal.fire({
                  icon: 'warning',
                  title: 'Missing Video ID',
                  text: 'No video ID was provided. Please select a valid video.',
                });
          }
        },
    
      loadImages() {
        const video_id = this.$route.params.video_id;
        if (video_id) {
          const token = localStorage.getItem('token');
          const LOAD_IMAGES_URL = `${process.env.VUE_APP_API_BASE_URL}/brand_management/view_image/${video_id}`;

          axios.get(LOAD_IMAGES_URL, {
            headers: {
              'Authorization': `Token ${token}`
            }
          })
          .then(response => {
            this.image_files = response.data; // Assuming response.data is an array of image data
          })
          .catch(error => {
            console.error('Error loading images:', error);
          });
        }
      },
      initImageUpload() {
        var initialUploadGroup = $('.upload-group').first().clone();
        
        // Add more image upload groups
        $('#addMoreImages').click(function() {
          var clonedGroup = initialUploadGroup.clone();
          clonedGroup.find('input').val('');
          clonedGroup.find('img#imagePreview').attr('src', ''); // Clear the image preview
          // Append the cloned group to the container
          $('#imageUploadContainer').append(clonedGroup);
        });
      
        // Handle image preview on file selection
        $(document).on('change', '#image_url', function(event) {
          var reader = new FileReader();
          var preview = $(this).siblings('#imagePreview');
          reader.onload = function(e) {
            preview.attr('src', e.target.result);
            preview.show();
          };
          reader.readAsDataURL(event.target.files[0]);
        });
      
        // Upload images
        $('#upload_image').on('click', async (e) => {
          e.preventDefault();
          Swal.fire({
            title: 'Uploading Images',
            text: 'Please wait while we upload your image(s)...',
            allowOutsideClick: false,
            showConfirmButton: false,
            didOpen: () => {
              Swal.showLoading();
            }
          });
      
          const video_id = this.$route.params.video_id;
          const formData = new FormData();
          const imageGroups = $('.upload-group');
          
          imageGroups.each(function() {
            const image_file = $(this).find('#image_url')[0].files[0];
            if (image_file) {
              formData.append('image_urls', image_file);
            }
            
            formData.append('description', $(this).find('#description').val());
            formData.append('product_name', $(this).find('#product_name').val());
            formData.append('price', $(this).find('#price').val());
            formData.append('no_of_items', $(this).find('#no_of_items').val());
            formData.append('category', $(this).find('#categories').val());
            // Handle sizes and colors
            $(this).find('#size_table tr.size-color-row').each(function() {
              const size = $(this).find('#size').val();
              const quantity = $(this).find('#size_quantity').val();
      
              $(this).find('.color-cell').each(function() {
                const color = $(this).find('#color').val();
                const color_quantity = $(this).find('#color_quantity').val();
                const price = $(this).find('#price').val();
      
                formData.append('sizes[]', size);
                formData.append('quantities[]', quantity);
                formData.append('colors[]', color);
                formData.append('color_quantities[]', color_quantity);
                formData.append('prices[]', price);
              });
            });
          });
      
          formData.append('brand_video_id', video_id);
      
          const token = localStorage.getItem('token');
          try {
            const UPLOAD_IMAGE_URL = `${process.env.VUE_APP_API_BASE_URL}/brand_management/upload_image/`;

            const response = await $.ajax({
              url: UPLOAD_IMAGE_URL,
              body: formData,
              headers: {
                'Authorization': `Token ${token}`
              }
            });
      
            if (response.ok) {
              Swal.fire({
                icon: 'success',
                title: 'Images Uploaded',
                text: 'Your image(s) have been successfully uploaded!',
              }).then(() => {
                window.location.reload();
              });
            } else {
              throw new Error('Upload failed');
            }
          } catch (error) {
            Swal.fire({
              icon: 'error',
              title: 'Upload Failed',
              text: 'There was an error uploading the image(s).',
            });
          }
        });
      },
      
  async deleteImage(image_id) {
    const token = localStorage.getItem('token');
    Swal.fire({
      title: 'Are you sure?',
      text: "You won't be able to revert this!",
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#E39356',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Yes, delete it!'
    }).then(async (result) => {
      if (result.isConfirmed) {
        Swal.fire({
          title: 'Deleting Image',
          text: 'Please wait while the image is being deleted...',
          allowOutsideClick: false,
          showConfirmButton: false,
          didOpen: () => {
            Swal.showLoading();
          }
        });
        try {

          const DELETE_IMAGES_URL = `${process.env.VUE_APP_API_BASE_URL}/brand_management//delete_image/${image_id}`;
          const response = await $.ajax({
            url: DELETE_IMAGES_URL,
            method: 'DELETE',
            headers: {
              'Authorization': `Token ${token}`
            }
          });
          if (response.ok) {
            Swal.fire(
              'Deleted!',
              'The image has been deleted.',
              'success'
            ).then(() => {
              window.location.reload();
            });
          } else {
            throw new Error('Deletion failed');
          }
        } catch (error) {
          Swal.fire({
            icon: 'error',
            title: 'Deletion Failed',
            text: 'Failed to delete image. Please try again.',
          });
        }
      }
    });
  },
  openUpdateModal(image) {
    if (image && image.id !== undefined) {
      this.current_image = {
        id: image.id,
        image_url: image.product.image_url || '',
        description: image.product.description || '',
        price: image.price || '',
        no_of_items: image.product.no_of_items || 0,
        quantity: image.quantity || 0,
        product_name: image.product.product_name || '',
        category__category_name: image.product.category__category_name || '',
        code: image.product.code || '',
        size: image.size || '',
        color: image.color || '',

      };

      // Show the modal
      $('#updateModalImage').modal('show');
    } else {
      console.error('Invalid image object:', image);
    }
  },


  async updateImage() {
    const token = localStorage.getItem('token');
    const formData = new FormData();
    const new_image_file = this.$refs.newImage.files[0];

    if (new_image_file) {
      formData.append('image_urls', new_image_file); // Append new image file
    }
    formData.append('product_name', this.current_image.product.product_name);
    formData.append('description', this.current_image.description);
    formData.append('no_of_items', this.current_image.no_of_items);
    formData.append('category', this.selectedCategory);
    formData.append('video_image_id',this.current_image.id)
    try {
      const UPDATE_IMAGE_URL = `${process.env.VUE_APP_API_BASE_URL}/brand_management/brand/`;
      const response = await $.ajax({
        url: UPDATE_IMAGE_URL,
        method: 'POST',
        body: formData,
        headers: {
          'Authorization': `Token ${token}`
        }
      });

      if (response.ok) {
        Swal.fire({
          icon: 'success',
          title: 'Images Updated',
          text: 'Your image(s) have been successfully updated!',
        }).then(() => {
          window.location.reload();
        });
      } else {
        throw new Error('Update failed');
      }
    } catch (error) {
      Swal.fire({
        icon: 'error',
        title: 'Update Failed',
        text: 'There was an error updating the image(s).'
      });
    }
  },
  async fetchCategories() {
    const token = localStorage.getItem('token');
    const FETCH_CATEGORIES_URL = `${process.env.VUE_APP_API_BASE_URL}/brand_management/categories/`;

    try {
      const response = await $.ajax({
        url: FETCH_CATEGORIES_URL,
        method: 'GET',
        headers: {
          'Authorization': `Token ${token}`,
          'Content-Type': 'application/json'
        }
      });
  
      this.categories = response;
    } catch (error) {
      console.error(error);
      Swal.fire({
        title: 'Error',
        text: 'Failed to fetch users.',
        icon: 'error',
        confirmButtonText: 'OK'
      });
    }
  },

  async addCategory() {
    const token = localStorage.getItem('token');
    const CREATE_CATEGORY_URL = `${process.env.VUE_APP_API_BASE_URL}/brand_management/create_categories/`;

    try {
      const response = await $.ajax({
        url: CREATE_CATEGORY_URL,
        method: 'POST',
        headers: {
          'Authorization': `Token ${token}`,
          'Content-Type': 'application/json'
        },
        data: JSON.stringify({ category_name: this.newCategory })
      });

      this.categories.push(response);
      this.newCategory = '';

      Swal.fire({
        title: 'Success',
        text: 'Category added successfully.',
        icon: 'success',
        confirmButtonText: 'OK'
      });
    } catch (error) {
      console.error('Error adding category:', error);
      Swal.fire({
        title: 'Error',
        text: 'Failed to add category.',
        icon: 'error',
        confirmButtonText: 'OK'
      });
    }
  },
}
};
$(document).ready(function() {

    $('#size_table').on('click', '.add-color-btn', function() {
        var $row = $(this).closest('tr');
        var color_cell = $row.find('.color-cell');
        var color_quantity_cell = $row.find('.color-quantity-cell');
        var color_price = $row.find('.color-price');
    
        color_cell.append('<input type="text" class="form-control form-control-sm mt-2" name="colorSmall">');
        color_quantity_cell.append('<input type="number" class="form-control form-control-sm mt-2" name="color_small_quantity">');
        color_price.append('<input type="text" class="form-control form-control-sm mt-2" name="color_price">');
      });
      $('#size_table').on('click', '.remove-color-btn', function() {
        var color_cell = $(this).closest('tr').find('.color-cell input').last();
        var color_quantity_cell = $(this).closest('tr').find('.color-quantity-cell input').last();
        var color_price = $(this).closest('tr').find('.color-price input').last();
    
        color_cell.remove();
        color_price.remove();
        color_quantity_cell.remove();
      });
    
      $('#addMore').on('click', function() {
        var $original_row = $('#size_table tr.size-color-row:first').clone(); 
        $original_row.find('input').val('');
    
        $original_row.find('.color-cell input:not(:first)').remove();
        $original_row.find('.color-quantity-cell input:not(:first)').remove();
        $original_row.find('.color-price input:not(:first)').remove();
    
        $('#size_table').append($original_row);
      });

      // update modal
      $('#update_size_table').on('click', '.update-add-color-btn', function() {
        var $row = $(this).closest('tr');
        var color_cell = $row.find('.update-color-cell');
        var color_quantity_cell = $row.find('.update-color-quantity-cell');
        var color_price = $row.find('.update-color-price');
    
        color_cell.append('<input type="text" class="form-control form-control-sm mt-2" name="colorSmall">');
        color_quantity_cell.append('<input type="number" class="form-control form-control-sm mt-2" name="color_small_quantity">');
        color_price.append('<input type="text" class="form-control form-control-sm mt-2" name="color_price">');
      });
      $('#update_size_table').on('click', '.update-remove-color-btn', function() {
        var color_cell = $(this).closest('tr').find('.update-color-cell input').last();
        var color_quantity_cell = $(this).closest('tr').find('.update-color-quantity-cell input').last();
        var color_price = $(this).closest('tr').find('.update-color-price input').last();
    
        color_cell.remove();
        color_price.remove();
        color_quantity_cell.remove();
      });
    });

