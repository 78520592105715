
import Home_navbar from '@/components/Home/Home_Navbar/HomeNavbarView.vue'
import { ref, onMounted, watch } from 'vue';
import jQuery from 'jquery';
import axios from 'axios';

export default {
  name: 'HomeNavbarView',
  components: {
    Home_navbar
  },
  setup() {
    const videos = ref([]);
    const showDetails = ref([]);
    const brand_videos = ref([]);
    const influencer_videos = ref([]);
    const currentPosition = ref(0);
    const visibleCards = ref(6);
    const hoveredVideo = ref(null);

    const initializeShowDetails = () => {
      showDetails.value = new Array(videos.value.length).fill(false);
    };

    const toggleDetails = (index) => {
      showDetails.value[index] = !showDetails.value[index];
    };

    const toggleOverlay = (index, isHovering) => {
      const overlay = jQuery('.overlay').eq(index);
      if (!showDetails.value[index] && isHovering) {
        overlay.addClass('bg-dark');
      } else {
        overlay.removeClass('bg-dark');
      }
    };

    const updateVisibleCards = () => {
      const windowWidth = jQuery(window).width();
      if (windowWidth <= 480) {
        visibleCards.value = 1;
      } else if (windowWidth <= 768) {
        visibleCards.value = 2;
      } else if (windowWidth <= 1200) {
        visibleCards.value = 4;
      } else {
        visibleCards.value = 6;
      }
    };

    const scrollCards = (direction) => {
      const maxScroll = videos.value.length - visibleCards.value;
      if (direction === 'right' && currentPosition.value < maxScroll) {
        currentPosition.value++;
      } else if (direction === 'left' && currentPosition.value > 0) {
        currentPosition.value--;
      }
    };

    onMounted(() => {
      updateVisibleCards();
      
      // jQuery event handlers
      jQuery(window).on('resize', updateVisibleCards);

      const $firstNavbar = jQuery('.first-navbar');
      const firstNavbarOffset = $firstNavbar.offset().top;

      jQuery(window).on('scroll', function() {
        if (jQuery(window).scrollTop() >= firstNavbarOffset) {
          $firstNavbar.addClass('position-fixed'); 
        } else {
          $firstNavbar.removeClass('position-fixed'); 
        }
      });
    });

    watch(videos, initializeShowDetails);

    return { 
      videos,
      brands: ref([]),
      showDetails,
      toggleDetails,
      toggleOverlay,
      brand_videos,
      influencer_videos,
      currentPosition,
      visibleCards,
      scrollCards,
      image_files: [],
      video_id:'',
      hoveredVideo,
     
    };
  },
  methods: {
    scrollCards(direction) {
      if (direction === 'left') {
        this.currentPosition = Math.max(this.currentPosition - 1, 0);
      } else if (direction === 'right') {
        this.currentPosition = Math.min(this.currentPosition + 1, this.videos.length - this.visibleCards);
      }
    },

    async fetchVideos() {
      try {
        const token = localStorage.getItem('token');
        const GET_ALL_VIDEOS_URL = `${process.env.VUE_APP_API_BASE_URL}/brand_management/get_all_videos/`;

        const response = await fetch(GET_ALL_VIDEOS_URL, {
          headers: {
            'Authorization': `Token ${token}`
          }
        });

        const data = await response.json();
        this.videos = data; 
      } catch (error) {
        console.error('Error fetching videos:', error);
      }
    },
    async fetchBrandVideos() {
      try {
        const token = localStorage.getItem('token');
        const GET_ALL__BRAND_VIDEOS_URL = `${process.env.VUE_APP_API_BASE_URL}/brand_management/all_brands_videos/`;
        const response = await fetch(GET_ALL__BRAND_VIDEOS_URL, {
          headers: {
            'Authorization': `Token ${token}`
          }
        });

        const data = await response.json();
        this.brand_videos = data;
        this.$nextTick(() => {
          this.loadImages(); // Call loadImages after brand_videos is loaded
        }); 
      } catch (error) {
        console.error('Error fetching videos:', error);
      }
    },

    async fetchInfluencersVideos() {
      try {
        const token = localStorage.getItem('token');
        const GET_ALL_INFLUENCER_VIDEOS_URL = `${process.env.VUE_APP_API_BASE_URL}/influencer_management/all_influencers_videos/`;
        const response = await fetch(GET_ALL_INFLUENCER_VIDEOS_URL, {
          headers: {
            'Authorization': `Token ${token}`
          }
        });

        const data = await response.json();
        this.influencer_videos = data; 
      } catch (error) {
        console.error('Error fetching videos:', error);
      }
    },
    loadImages() {
      if (this.brand_videos && this.brand_videos.length > 0) {  // Check if brand_videos exists and has data
        const video_id = this.brand_videos[0].id;  // Access the video ID

        const token = localStorage.getItem('token');
        const VIEW_IMAGE_URL = `${process.env.VUE_APP_API_BASE_URL}/brand_management/view_image/${video_id}`;

        axios.get(VIEW_IMAGE_URL, {
          headers: {
            'Authorization': `Token ${token}`
          }
        })
        .then(response => {
          this.image_files = response.data; // Assuming response.data is an array of image data
        })
        .catch(error => {
          console.error('Error loading images:', error);
        });
      }
    },

    showPopUp(index) {
      this.hoveredVideo = index;
  
      this.$nextTick(() => {
        const videoElement = this.$refs.videoElement[index];
        
        if (videoElement) {
          videoElement.play().catch(error => {
            console.error("Error playing video:", error);
          });
        }
      });
    },
    hidePopUp(index) {
      this.hoveredVideo = null;
  
      this.$nextTick(() => {
        const videoElement = this.$refs.videoElement[index];
        
        if (videoElement) {
          videoElement.pause();
          videoElement.currentTime = 0; // Reset the video to the beginning
        }
      });
    }
  
  },
  mounted() {
    this.fetchVideos();
    this.fetchBrandVideos();
    this.fetchInfluencersVideos();
    this.loadImages();
    this.showPopUp();
    this.hidePopUp();
   
  },
};