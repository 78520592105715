<Sidebar />

<Navbar />

<div class=" app-content">  
    
    <div class="content">
      <div class="upload-search-row">
        <div></div>
      </div>
      <div class="card table-card">
        <div class="card-body">
          <table id="content_table" class="table align-item table-flush-item content-table">
            <thead class="thead-light">
              <tr>
                <th class="text-uppercase text-xxs fw-bold opacity-9">Item</th>
                <th class="text-uppercase text-xxs fw-bold opacity-9">Description</th>
                <th class="text-uppercase text-xxs fw-bold opacity-9">Code</th>
                <th class="text-uppercase text-xxs fw-bold opacity-9">Price</th>
                <th class="text-uppercase text-xxs fw-bold opacity-9">Sold</th>
                <th class="text-uppercase text-xxs fw-bold opacity-9">Total Sales</th>
                <th class="text-uppercase text-xxs fw-bold opacity-9">In Stock</th>
                <th class="text-uppercase text-xxs fw-bold opacity-9">Status</th>
                <th class="text-uppercase text-xxs fw-bold opacity-9">Action</th>
              </tr>
            </thead>
            <tbody>
              <tr v-for="image in images" :key="image.id">
                <td class="align-item"> 
                  <a :href="image.product.image_url" target="_blank">
                    <i class="fa-regular fa-image text-black fs-3"></i> 
                  </a>
                </td>
                <td class="align-item">
                  <span class="text-xxs font-weight-light mb-0">{{ image.product.product_name }}</span>
                </td>
                <td class="align-item">
                  <span class="text-xxs font-weight-light mb-0">{{ image.product.code ? image.product.code : 'N/A'}}</span>
                </td>
                <td class="align-item">
                  <span class="text-xxs font-weight-light mb-0">R{{ image.price }}</span>
                </td>
                <td class="align-item">
                  <span class="text-xxs font-weight-light mb-0">{{ image.sold ?  image.sold : '0' }}</span>
                </td>
                <td class="align-item">
                  <span class="text-xxs font-weight-light mb-0">{{ image.total_sales ? image.total_sales : '0'}}</span>
                </td>
                <td class="align-item">
                  <span class="text-xxs font-weight-light mb-0">{{ image.quantity }}</span>
                </td>
                <td class="align-item">
                  <span class="badge badge-pill badge-warning text-warning">
                    {{ image.stock_status__status_name }}
                  </span>
                </td>
                <td class="align-item">
                  <router-link :to="{ name: 'manage_content', params: { video_id: image.product.brand_video_id } }">
                  <button class="btn-sm action-btn font-weight-light text-xxs manage-btn">View</button>
                  </router-link>
                  <button class="btn-sm btn-danger font-weight-light text-xxs delete-btn" @click="deleteImage(image.id)">Delete</button>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
    </div> 
</div>
