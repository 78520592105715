import Influencer_navbar from '@/components/Influencer/Influencer_Navbar/InfluencerNavbarView.vue'
import Influencer_sidebar from '@/components/Influencer/Influencer_Sidebar/InfluencerSidebarView.vue'
import jQuery from 'jquery';

export default {
  components: {
    Influencer_navbar,
    Influencer_sidebar
  },
  mounted() {
    const $searchIcon = jQuery('#searchIcon');
    const $searchInput = jQuery('#searchInput');

    // Toggle the search input and icon on click
    $searchIcon.on('click', function(event) {
      event.stopPropagation();
      $searchInput.toggleClass('d-none'); // Show/hide the search input
      $searchIcon.toggleClass('bx-search bx-x'); // Toggle between search and close icon
    });
  },
}
