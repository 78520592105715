
import $ from 'jquery'; // Ensure jQuery is imported if used
import Swal from 'sweetalert2'; // Import SweetAlert2

export default {
  name: 'RegisterView',
  data() {
    return { isBrand: true,form: { first_name: '', last_name: '', email: '', password: '', confirm_password: '', phone_number: '',brand_name: '', agree: false, role: '' },  errorMessage: '', successMessage: '', emailErrorMessage: '' };
  },
  mounted() {
    
    const names_regex = /^[a-zA-Z]+$/;

    $('#first_name, #last_name').on('keydown', function(event) {
      const keyPressed = String.fromCharCode(event.which || event.keyCode);
      if (!names_regex.test(keyPressed) && !event.metaKey && !event.ctrlKey && !event.altKey) {
        event.preventDefault();
      }
    });
    // Bind the updatePasswordRequirements function to the password field
    $('#password').on('input', this.updatePasswordRequirements);
  },

  created() {
    const selectedRole = localStorage.getItem('selectedRole');
    this.isBrand = selectedRole === 'brand';
    this.form.role = selectedRole; // Ensure role is set in the form data

  },
  methods: {
    validateForm() {
      const password_pattern = /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*?&#()^><])[A-Za-z\d@$!%*?&#()^><]{8,}$/;
      const email_pattern = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;

      if (!email_pattern.test(this.form.email)) {
        this.emailErrorMessage = 'Invalid email format. Please enter a valid email address.';
        return false;
      }

      if (!password_pattern.test(this.form.password)) {
        this.errorMessage = 'Password must be at least eight characters long, contain one uppercase letter, one lowercase letter, one number, and one special character.';
        return false;
      }
      if (this.form.password !== this.form.confirm_password) {
        this.errorMessage = 'Passwords do not match.';
        return false;
      }

      this.errorMessage = ''; // Clear error message if all validations pass
      this.emailErrorMessage = ''; // Clear email error message if valid
      return true;
    },
    updatePasswordRequirements() {
      const password = $('#password').val();
      const meets_length_requirement = password.length >= 8;
      const meets_uppercase_requirement = /[A-Z]/.test(password);
      const meets_lowercase_requirement = /[a-z]/.test(password);
      const meets_digit_requirement = /\d/.test(password);
      const meets_special_char_requirement = /[@$!%*?&#()^><]/.test(password);

      // Update the checkmarks based on requirements
      $('#length-requirement').toggleClass('text-success', meets_length_requirement);
      $('#uppercase-requirement').toggleClass('text-success', meets_uppercase_requirement);
      $('#lowercase-requirement').toggleClass('text-success', meets_lowercase_requirement);
      $('#digit-requirement').toggleClass('text-success', meets_digit_requirement);
      $('#specialchar-requirement').toggleClass('text-success', meets_special_char_requirement);
    },
   
    async onSubmit() {
      if (!this.validateForm()) {
        Swal.fire({
          title: 'Validation Error',
          text: this.errorMessage || this.emailErrorMessage,
          icon: 'error',
          confirmButtonText: 'OK'
        });
        return;
      }

      try {
        const REGISTER_URL = `${process.env.VUE_APP_API_BASE_URL}/brand_management/register/`;
        const response = await $.ajax({
          url: REGISTER_URL,
          method: 'POST',
          contentType: 'application/json',
          data: JSON.stringify(this.form),
          dataType: 'json',
        });

        const responseData = typeof response === 'string' ? JSON.parse(response) : response;

        if (responseData.status === 'success') {
          Swal.fire({
            title: 'Success',
            text: responseData.message,
            icon: 'success',
            confirmButtonText: 'OK'
          }).then(() => {
            // Redirect to the login page after the user clicks 'OK'
            window.location.href = '/login';
          });
          this.successMessage = responseData.message;
          this.errorMessage = '';
        } else {
          Swal.fire({
            title: 'Error',
            text: responseData.message,
            icon: 'error',
            confirmButtonText: 'OK'
          });
          this.errorMessage = responseData.message;
          this.successMessage = '';
        }
      } catch (error) {
        if (error.response) {
          const errorMessage = error.responseJSON ? error.responseJSON.message : 'An error occurred during registration.';
          Swal.fire({
            title: 'Error',
            text: errorMessage,
            icon: 'error',
            confirmButtonText: 'OK'
          });
          this.errorMessage = errorMessage;
        } else {
          Swal.fire({
            title: 'Network Error',
            text: 'Network error. Please try again.',
            icon: 'error',
            confirmButtonText: 'OK'
          });
          this.errorMessage = 'Network error. Please try again.';
        }
        this.successMessage = '';
      }
    }
  }
};

