import $ from 'jquery'; // Ensure jQuery is imported if used
import Swal from 'sweetalert2'; // Import SweetAlert2

export default {
  name: 'LoginView',
  data() {
    return {
      form: { email: '', password: ''},
      errorMessage: ''
    };
  },
  methods: {
    setRoleAndRedirect(role) {
      localStorage.setItem('selectedRole', role);
      window.location.href = '/register';
    },
    async onSubmit() {
      try {
        const LOGIN_URL = `${process.env.VUE_APP_API_BASE_URL}/system_management/login/`;
        const response = await $.ajax({
          url: LOGIN_URL,
          method: 'POST',
          contentType: 'application/json',
          data: JSON.stringify(this.form),
          dataType: 'json',
        });

        const responseData = typeof response === 'string' ? JSON.parse(response) : response;

        if (responseData.status === 'success') {
          await Swal.fire({
            title: 'Success',
            text: 'Login successful',
            icon: 'success',
            confirmButtonText: 'OK',
          });
          localStorage.setItem('token', responseData.token);
          if (responseData.user.role__name === 'BRAND' && responseData.first_login) {
            this.$router.push('/brand');
           
          } else {
            // Redirect based on user role
            switch (responseData.user.role__name) {
              case 'admin':
                this.$router.push('/admin_dashboard');
                break;
              case 'INFLUENCER':
                this.$router.push('/Influencer');
                break;
              case 'BRAND':
                this.$router.push('/dashboard');
                break;
              case 'CUSTOMER':
                this.$router.push('/customer_dashboard');
                break;
              default:
                this.$router.push('/dashboard');
                break;
            
          }
          }
        } else {
          await Swal.fire({
            title: 'Error',
            text: responseData.message,
            icon: 'error',
            confirmButtonText: 'OK',
          });
          this.errorMessage = responseData.message;
        }
      } catch (error) {
        console.error('Error occurred:', error);

        let errorMessage = 'An error occurred during login.';
        
        // If the error response is in JSON format, extract the message
        if (error.responseJSON && error.responseJSON.message) {
          errorMessage = error.responseJSON.message;
        } else if (error.responseText) {
          // Fallback to responseText if JSON is not available
          errorMessage = 'Invalid credentials';
        }

        await Swal.fire({
          title: 'Error',
          text: errorMessage,
          icon: 'error',
          confirmButtonText: 'OK',
        });

        this.errorMessage = errorMessage;
      }
    }
  }
};
