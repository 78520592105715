<div class="dashboard">
    <Admin_sidebar />
    <div class="main-content">
      <Admin_navbar />
      <router-view />

      <div class="content">
        <!-- Main content goes here -->
      </div>
    </div>
  </div>