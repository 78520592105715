import { createRouter, createWebHistory } from 'vue-router'
import Register from '@/views/Register/RegisterView.vue'
import Login from '@/views/Login/LoginView.vue'
import Navbar from '@/components/Brand/Navbar/NavbarView.vue'
import Sidebar from '@/components/Brand/Sidebar/SideView.vue'
import Dashboard from '@/views/Dashboard/DashboardView.vue'
import Content_Upload from '@/views/Brand_Management/Content_Upload/ContentUploadView.vue'
import Manage_Content from '@/views/Brand_Management/Manage_Video/ManageView.vue'
import Inventory from '@/views/Brand_Management/Inventory/InventoryView.vue'
import Brand from '@/views/Brand_Management/Brand/BrandView.vue'
import update_brand from '@/views/Brand_Management/Update_brand/UpdateBrand.vue'
import update_user from '@/views/Brand_Management/update_user/UpdateUser.vue'
import Team from '@/views/Brand_Management/Team/TeamView.vue'
import Home from '@/views/Home/HomeView.vue'
import Home_navbar from '@/components/Home/Home_Navbar/HomeNavbarView.vue'


// Admin
import Admin_sidebar from '@/components/Admin/Admin_sidebar/AdminView.vue'
import Admin_dashboard from '@/views/Admin_Management/AdminDashView.vue'
import Admin_navbar from '@/components/Admin/Admin_Navbar/AdminNavView.vue'

// Influencer
import Influencer from '@/views/Influencer_Management/InfluencerDashView.vue'
import Influencer_navbar from '@/components/Influencer/Influencer_Navbar/InfluencerNavbarView.vue'
import Influencer_sidebar from '@/components/Influencer/Influencer_Sidebar/InfluencerSidebarView.vue'
import Influencer_profile from '@/views/Influencer_Management/Influencer_profile/InfluencerProfileView.vue'

// Customer
import Customer_navbar from '@/components/Customer/CustomerNavView.vue'
import Customer_dashboard from '@/views/Customer_Management/CustomerDashView.vue'
import Customer_profile from '@/views/Customer_Management/Customer_Profile/CustomerProfileView.vue'





<link
    rel="stylesheet"
    href="https://cdnjs.cloudflare.com/ajax/libs/font-awesome/6.0.0-beta3/css/all.min.css"
    integrity="sha384-k6RqeWeci5ZR/Lv4MR0sA0FfDOM/NmYd8xEUh6T9xQ+Lz5FHR4D0l4lm1Jv3V7z"
    crossorigin="anonymous"
  />


const routes = [
  {
  path: '/',
    name: 'home',
    component: Home
  },
  {
    path: '/login',
    name: 'login',
    component: Login
  },
  {
    path: '/register',
    name: 'register',
    component: Register
  },
  {
    path: '/navbar',
    name: 'navbar',
    component: Navbar
  },
  {
    path: '/sidebar',
    name: 'sidebar',
    component: Sidebar
  },
  {
    path: '/dashboard',
    name: 'dashboard',
    component: Dashboard
  },

  {
    path: '/content_upload',
    name: 'content_upload',
    component: Content_Upload
  },
  {
    path: '/brand',
    name: 'brand',
    component: Brand
  },
  {path: '/update_brand',
    name: 'update_brand',
    component: update_brand
  },
  { 
   path: '/manage_content/:video_id',
    name: 'manage_content',
    component: Manage_Content },
    {path: '/update_user',
      name: 'update_user',
      component: update_user
    },
  
    {
      path: '/inventory',
      name: 'inventory',
      component: Inventory
    },
    {
      path: '/add_user',
      name: 'add_user',
      component: Team
    },

    {
      path: '/Home_navbar',
      name: 'Home_navbar',
      component: Home_navbar
    },
    // Admin
    {
      path: '/Admin_sidebar',
      name: 'Admin_sidebar',
      component: Admin_sidebar
    },
    {
      path: '/Admin_navbar',
      name: 'Admin_navbar',
      component: Admin_navbar
    },
    {
      path: '/Admin_dashboard',
      name: 'Admin_dashboard',
      component: Admin_dashboard
    },

    // Influencer
    {
      path: '/Influencer',
      name: 'Influencer',
      component: Influencer
    },

    {
      path: '/Influencer_navbar',
      name: 'Influencer_navbar',
      component: Influencer_navbar
    },

    {
      path: '/Influencer_sidebar',
      name: 'Influencer_sidebar',
      component: Influencer_sidebar
    },

    {
      path: '/Influencer_profile',
      name: 'Influencer_profile',
      component: Influencer_profile
    },

    // Customer
    {
      path: '/Customer_navbar',
      name: 'Customer_navbar',
      component: Customer_navbar
    },

    {
      path: '/Customer_dashboard',
      name: 'Customer_dashboard',
      component: Customer_dashboard
    },

    {
      path: '/Customer_profile',
      name: 'Customer_profile',
      component: Customer_profile
    },

]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
})

export default router
