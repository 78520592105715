import Sidebar from '@/components/Brand/Sidebar/SideView.vue';
import Navbar from '@/components/Brand/Navbar/NavbarView.vue';
import Swal from 'sweetalert2';
import $ from 'jquery';

export default {
  components: {
    Sidebar,
    Navbar
  },

  data() {
    return {
      logoUrl: require('@/assets/images/icon.jpg'), // Default placeholder

      form: {

        first_name: '',
        last_name: '',
        email: '',
        phone_number: '',
        profileImage: null,
      },
      errorMessage: '',
      emailErrorMessage: ''
    };
  },

  mounted() {
    this.fetchBrandData();
  },

  methods: {
    async fetchBrandData() {
      const token = localStorage.getItem('token');

      try {
        const FETCH_USER_DATA_URL = `${process.env.VUE_APP_API_BASE_URL}/system_management/user_info/`;

        const response = await $.ajax({
          url: FETCH_USER_DATA_URL,
          method: 'GET',
          headers: {
            'Authorization': `Token ${token}`,
            'Content-Type': 'application/json'
          },
        });

        if (!response.ok) {
          throw new Error('Failed to fetch brand data');
        }

        const data = await response.json();
        localStorage.setItem('user_id', data.user_id);

        if (data.status === "success") {
          this.form.email = data.email || '';
          this.form.phone_number = data.phone_number || '';
          this.form.first_name = data.first_name || '';
          this.form.last_name = data.last_name || '';
          this.form.user_id = data.user_id; 
          this.form.brand_id = data.brand_id; 
          this.logoUrl = data.logo_url || this.logoUrl;
        } else {
          Swal.fire({
            title: 'Error',
            text: data.error || 'Failed to fetch brand data.',
            icon: 'error',
            confirmButtonText: 'OK'
          });
        }
      } catch (error) {
        Swal.fire({
          title: 'Error',
          text: 'Failed to fetch brand data.',
          icon: 'error',
          confirmButtonText: 'OK'
        });
      }
    },

    validateForm() {
      const namePattern = /^[a-zA-Z]+$/;
      const emailPattern = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
      const phonePattern = /^[0-9]+$/;

      if (!namePattern.test(this.form.first_name)) {
        this.errorMessage = 'First name should not contain numbers or special characters.';
        return false;
      }

      if (!namePattern.test(this.form.last_name)) {
        this.errorMessage = 'Last name should not contain numbers or special characters.';
        return false;
      }

      if (!emailPattern.test(this.form.email)) {
        this.emailErrorMessage = 'Invalid email format. Please enter a valid email address.';
        return false;
      }

      if (!phonePattern.test(this.form.phone_number)) {
        this.errorMessage = 'Phone number should only contain numbers.';
        return false;
      }

      this.errorMessage = '';
      this.emailErrorMessage = '';

      return true;
    },

    async updateBrandInfo() {
      if (!this.validateForm()) {
        Swal.fire({
          title: 'Validation Error',
          text: this.errorMessage || this.emailErrorMessage,
          icon: 'error',
          confirmButtonText: 'OK'
        });
        return;
      }

      const token = localStorage.getItem('token');

      const formData = new FormData();

      formData.append('email', this.form.email);
      formData.append('phone_number', this.form.phone_number);
      formData.append('first_name', this.form.first_name);
      formData.append('last_name', this.form.last_name);
      formData.append('user_id', localStorage.getItem('user_id')); 
      formData.append('brand_id', this.form.brand_id); 

      if (this.form.profileImage) {
        // Convert Base64 to Blob and append to FormData
        const response = await fetch(this.form.profileImage);
        const blob = await response.blob();
        formData.append('logo', blob, 'logo.png');
      }

      try {
        const UPDATE_USER_URL = `${process.env.VUE_APP_API_BASE_URL}/system_management/get_user_info/`;

        const response = await $.ajax({
          url: UPDATE_USER_URL,
          method: 'POST',
          headers: {
            'Authorization': `Token ${token}`
          },
          body: formData
        });

        if (!response.ok) {
          throw new Error('Failed to update brand data');
        }

        const data = await response.json();

        if (data.status === "success") {
          Swal.fire({
            title: 'Success',
            text: 'Brand information updated successfully.',
            icon: 'success',
            confirmButtonText: 'OK'

          });
        } else {
          Swal.fire({
            title: 'Error',
            text: data.error || 'Failed to update brand data.',
            icon: 'error',
            confirmButtonText: 'OK'
          });
        }
      } catch (error) {
        Swal.fire({
          title: 'Error',
          text: 'An error occurred during the submission.',
          icon: 'error',
          confirmButtonText: 'OK'
        });
      }
    },

    onFileChange(event) {
        const file = event.target.files[0];
      if (file && file.type.startsWith('image/')) {
        const reader = new FileReader();
        reader.onload = (e) => {
          this.form.profileImage = e.target.result; // Set the uploaded image as the source
        };
        reader.readAsDataURL(file);
      }

    }
  }
}
