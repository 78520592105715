/* global $ */
import Sidebar from '@/components/Brand/Sidebar/SideView.vue';
import Navbar from '@/components/Brand/Navbar/NavbarView.vue';
import Swal from 'sweetalert2';

export default {
  name: 'InventoryView',
  components: {
    Sidebar,
    Navbar,
  },
  data() {
    return {
      dataTable: null,
      images: []
    };
  },
  mounted() {
    this.fetchImages();
  },
  methods: {
    initializeDataTable() {
      this.$nextTick(() => {
        if ($.fn.DataTable.isDataTable('#content_table')) {
          $('#content_table').DataTable().destroy(); // Destroy previous instance
        }
        this.dataTable = $('#content_table').DataTable({
          pageLength: 10,
          lengthChange: false,
          searching: true,
          ordering: true,
          paging: true,
          info: true,
          autoWidth: false,
        
        });
      });
    },
    async fetchImages() {
      try {
        const token = localStorage.getItem('token');
        const FETCH_IMAGES_URL = `${process.env.VUE_APP_API_BASE_URL}/brand_management/get_all_inventory/`;
        const response = await $.ajax({
          url: FETCH_IMAGES_URL,
          method: 'GET',
          headers: {
            'Authorization': `Token ${token}`
          }
        });
        const data = await response.json();
        this.images = data;
        this.$nextTick(() => {
          this.initializeDataTable(); // Initialize DataTable after images are rendered
        });
      } catch (error) {
        console.error('Error fetching images:', error);
      }
    },
  
  async deleteImage(image_id) {
    const token = localStorage.getItem('token');
    Swal.fire({
      title: 'Are you sure?',
      text: "You won't be able to revert this!",
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#E39356',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Yes, delete it!'
    }).then(async (result) => {
      if (result.isConfirmed) {
        Swal.fire({
          title: 'Deleting Image',
          text: 'Please wait while the image is being deleted...',
          allowOutsideClick: false,
          showConfirmButton: false,
          didOpen: () => {
            Swal.showLoading();
          }
        });
        try {
          const DELETE_IMAGE_URL = `${process.env.VUE_APP_API_BASE_URL}/brand_management/delete_image/${image_id}`;
          const response = await $.ajax( {
            url: DELETE_IMAGE_URL,
            method: 'DELETE',
            headers: {
              'Authorization': `Token ${token}`
            }
          });
          if (response.ok) {
            Swal.fire(
              'Deleted!',
              'The image has been deleted.',
              'success'
            ).then(() => {
              window.location.reload();
            });
          } else {
            throw new Error('Deletion failed');
          }
        } catch (error) {
          Swal.fire({
            icon: 'error',
            title: 'Deletion Failed',
            text: 'Failed to delete image. Please try again.',
          });
        }
      }
    });
  },
  beforeDestroy() {
    if (this.dataTable) {
      this.dataTable.destroy();
    }
  },
  }
};
