<link rel="stylesheet" href="https://cdn.jsdelivr.net/npm/bootstrap-icons@1.10.5/font/bootstrap-icons.css">
<div class="dashboard">
    <Sidebar />
    <div class="main-content">
      <Navbar />

      <div class="card">
        <div id="profile" class="card-body">
          <h5>Profile</h5>
          <div class="row">
            <div class="col-md-12">
              <form class="brand-form" @submit.prevent="updateBrandInfo">

              <!-- Profile Section -->
              <div class="profile-section text-center">
                <div class="image-container">
                  <img :src="form.profileImage || logoUrl" alt="Profile Image" class="profile-image" id="profileImage">
                </div>
                <div class="upload-icon-container">
                  <label for="logoInput" class="upload-icon">
                    <i class="bi bi-camera"></i>
                  </label>
                  <input type="file" id="logoInput" accept="image/*" class="file-input" @change="onFileChange">
                </div>
              </div>


                <!-- First Name and Last Name -->
                <div class="form-group">
                  <div class="row">
                    <div class="col-md-6">
                      <label for="owner_first_name" class="col-form-label">First Name:</label>
                      <input type="text" class="form-control form-control-sm" id="first_name" v-model="form.first_name" required />
                    </div>
                    <div class="col-md-6">
                      <label for="owner_last_name" class="col-form-label">Last Name:</label>
                      <input type="text" class="form-control form-control-sm" id="last_name" v-model="form.last_name" required />
                    </div>
                  </div>
                </div>

                      <!-- Email and Phone Number -->
                      <div class="form-group">
                        <div class="row">
                          <div class="col-md-6">
                            <label for="email" class="col-form-label">Email Address:</label>
                            <input type="email" class="form-control form-control-sm" id="email" v-model="form.email" required />
                          </div>
                          <div class="col-md-6">
                            <label for="phone_number" class="col-form-label">Phone Number:</label>
                            <input type="tel" class="form-control form-control-sm" id="phone_number" v-model="form.phone_number" required />
                          </div>
                        </div>
                      </div>

                <!-- Submit Button -->
                <button type="submit" variant="primary" class="sub-button">Update</button>
              </form>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>