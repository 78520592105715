/* global $ */
import Swal from 'sweetalert2';
import Influencer_sidebar from '@/components/Influencer/Influencer_Sidebar/InfluencerSidebarView.vue';
import Influencer_navbar from '@/components/Influencer/Influencer_Navbar/InfluencerNavbarView.vue';

export default {
  name: 'ContentUploadView',
  components: {
    Influencer_sidebar,
    Influencer_navbar
  },

    data(){
    return { 
      showBrandDropdown: false,
      showProductDropdown: false,
      tagInput: '',
      searchQuery: '',
      filteredBrands:[],
      videos:[],
      brands:[],
      products:[],
      filteredProducts:[],
      selectedProductIds: [],  // Initialize as an empty array
      selectedProducts: [],
    };
    
},
  methods: {
    async fetchVideos() {

      try {
        const token = localStorage.getItem('token');
        const VIEW_CONTENT_URL = `${process.env.VUE_APP_API_BASE_URL}/influencer_management/view_content/`;

        const response = await fetch(VIEW_CONTENT_URL, {
          headers: {
            'Authorization': `Token ${token}`
          }
        });

        const data = await response.json();
        this.videos = data; 
      } catch (error) {
        console.error('Error fetching videos:', error);
      }
    
  
    },
    uploadVideo() {
      $('.action-btn').on('click', async (e) => {
        e.preventDefault();
        
        Swal.fire({
          title: 'Uploading Video',
          text: 'Please wait while we upload your video...',
          allowOutsideClick: false,
          showConfirmButton: false,
          didOpen: () => {
              Swal.showLoading();
          }
        });

        const formData = new FormData();
        formData.append('caption', $('#caption').val());
        formData.append("brand_id", this.selectedBrandId);
        this.selectedProductIds.forEach(id => {
          formData.append('products', id);
        });
        formData.append('video_url', $('#video_url')[0].files[0]);

        const token = localStorage.getItem('token');
        const UPLOAD_CONTENT_URL = `${process.env.VUE_APP_API_BASE_URL}/influencer_management/upload_content/`;

        try {
          const response = await fetch(UPLOAD_CONTENT_URL, {
            method: 'POST',
            body: formData,
            headers: {
              'Authorization': `Token ${token}`
            }
          });

          if (response.ok) {
            Swal.fire({
              icon: 'success',
              title: 'Upload Successful',
              text: 'Your video has been uploaded successfully!',
            }).then(() => {
              window.location.reload();
            });
          } else {
            throw new Error('Upload failed');
          }
        } catch (error) {
          Swal.fire({
            icon: 'error',
            title: 'Upload Failed',
            text: 'Failed to upload video. Please try again.',
          });
        }
      });
    },
  async fetchBrands() {

    try {
      const token = localStorage.getItem('token');
      const GET_ALL_PRODUCTS_URL = `${process.env.VUE_APP_API_BASE_URL}/brand_management/get_all_brands/`;
      const response = await fetch(GET_ALL_PRODUCTS_URL, {
        headers: {
          'Authorization': `Token ${token}`
        }
      });

      const data = await response.json();
      this.brands = data;  // Assign the data to the videos property
    } catch (error) {
      console.error('Error fetching videos:', error);
    }
  

  },
  filterBrands() {
    if (this.tagInput.startsWith('@')) {
      this.showBrandDropdown = true;
      const query = this.tagInput.slice(1).toLowerCase(); // Remove '@' and make it lowercase
      this.filteredBrands = this.brands.filter(brand =>
        brand.brand_name.toLowerCase().includes(query)
      );
      this.showBrandDropdown = this.filteredBrands.length > 0;
    } else {
      this.showBrandDropdown = false;
    }
  },
  selectBrand(brand) {
    this.tagInput = `@${brand.brand_name}`;
    this.selectedBrandId = brand.id;
    this.showBrandDropdown = false;
    
  },
  async fetchProducts() {

    try {
      const token = localStorage.getItem('token');
      const GET_ALL_BRANDS_URL = `${process.env.VUE_APP_API_BASE_URL}/brand_management/get_all_products/`;

      const response = await fetch(GET_ALL_BRANDS_URL, {
        headers: {
          'Authorization': `Token ${token}`
        }
      });

      const data = await response.json();
      this.products = data;  // Assign the data to the videos property
    } catch (error) {
      console.error('Error fetching videos:', error);
    }
  },
  filterProducts() {
    const query = this.searchQuery.toLowerCase(); // Convert query to lowercase for case-insensitive search
    if (query) {
      this.filteredProducts = this.products.filter(product =>
        product.product.product_name.toLowerCase().includes(query)
      );
      this.showProductDropdown = this.filteredProducts.length > 0;
    } else {
      this.showProductDropdown = false;
    }
  },
  selectProduct(product) {
    if (!this.selectedProductIds) {
      this.selectedProductIds = [];
    }
  
    const isProductSelected = this.selectedProductIds.some(id => id === product.id);
  
    if (!isProductSelected) {
      this.selectedProductIds.push(product.id);
      this.selectedProducts.push(product);
    }
  
    this.searchQuery = '';
    this.showProductDropdown = false;
  },

  removeProduct(product) {
    this.selectedProducts = this.selectedProducts.filter(p => p !== product);
  },
  handleClickOutside(event) {
    const dropdown = this.$refs.dropdownContainer;
    if (dropdown && !dropdown.contains(event.target)) {
      this.showProductDropdown = false;
    }
  },
  
  toggleDetails(index) {
    // Your method to toggle details view
    this.$set(this.showDetails, index, !this.showDetails[index]);
  }
},

  mounted() {
    this.fetchVideos();
    this.fetchBrands();
    this.fetchProducts();
    this.handleClickOutside();
  },
  beforeDestroy() {
    this.handleClickOutside();
  }
  
};



